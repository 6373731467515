<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Dafor%20S.r.l%20Via%eugenio%20Montale%201,%20Battipaglia%20Salerno,%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Il Migliore Ente di Formazione Accreditato e Certificato</h2>
                    <p>La società <b>DaFor S.r.l. – Servizi & Formazione</b> – ha come oggetto sociale l’attività di SERVIZI DI CONSULENZA e di FORMAZIONE PROFESSIONALE ed è Accreditato presso la Regione Campania quale Ente di Formazione Professionale la cui attività è rivolta all'organizzazione e l'erogazione di attività formative e/o di orientamento, finanziate attraverso risorse proprie e/o pubbliche.</p>
                    <p>Essa nasce da un' idea del suo fondatore, nonché Amministratore Unico, Dr. Paonessa Antonello, il quale ha acquisito negli anni un esperienza sia nel settore della consulenza, sia nel settore della Formazione.</p>
                    <p>
                        Per quanto concerne la <b>Formazione</b>, la <b>DaFor S.r.l.</b> è un ente di formazione professionale <b>Accreditato dalla Regione Campania</b> la cui attività è rivolta alla realizzazione di corsi di formazione professionale Finanziati con Fondi pubblici o Autofinanziati  con retta a carico dei singoli utenti e delle Aziende, di corsi di formazione in tema di sicurezza negli ambienti di lavoro, di corsi professionali finalizzati al recupero degli anni scolastici ed all'erogazione di servizi alle aziende (soprattutto orientate al settore turistico).
                    </p>

                    <p>La finalità del <b>Centro di Formazione Professionale “DaFor S.r.l.”</b>, come da suo slogan “<b>Il Centro che da forma al tuo futuro</b>” , è la diffusione della cultura della formazione professionale e del lavoro nel mondo giovanile e non, l’ ampliamento della conoscenza della formazione professionale e del mondo del lavoro in genere attraverso un’ interazione continua tra persone ed enti sia pubblici che privati.</p>
                    <p>
                        <b>Il Centro di Formazione Professionale “DaFor S.r.l.”</b> mira ad offrire un valore aggiunto alle imprese ed ai lavoratori grazie all'attuazione di progetti formativi volti al conseguimento di specifiche qualifiche promosse dalle istituzioni e riconosciute da enti pubblici quali Regioni, Province, Comuni, Università e Centri di Ricerca, strutture sanitarie.
                    </p>
                    <p>
                        L'attività del <b>Centro di Formazione Professionale “DaFor S.r.l.”</b> non si rivolge solo ai lavoratori ed alle aziende ma anche ai giovani nel fornire loro la possibilità di recuperare gli anni scolastici, acquisire qualifiche professionali per avere così una marcia in più nel mondo del lavoro.
                    </p>
                    <p>
                        La DaFor S.r.l. si ispira a quanto detto dal premio Nobel per la Pace, Nelson Mandela:

<strong>"L'istruzione e la formazione sono le armi più potenti che si possono utilizzare per cambiare il mondo"</strong></p>

<p>Inoltre la DaFor S.r.l. , con il proprio marchio Punto Qualità, è una società di sevizi che opera in varie discipline di interesse aziendale, in grado di offrire assistenza alle varie realtà produttive e in particolare al settore: Agro-Alimentare, Turistico, Tempo Libero-Benessere e Servizi Pubblici.</p>

<p>La nostra strategia è volta a fornire un aiuto costante ai propri clienti, consentendo loro di anticipare le trasformazioni economiche, tecnologiche e fornendo loro soluzioni innovative ad alto valore aggiunto.</p>

<p>Il gruppo di lavoro riunisce diverse figure professionali in grado di rispondere alle richieste specifiche in termini di formazione e servizi.</p>

<p>
    <strong>
        FIGURE PROFESSIONALI - SETTORE FORMAZIONE:
    </strong><br>
        Docenti di Lettere, Matematica e Fisica, Chimica, Scienze Naturali, Scienze dell’educazione, Sociologia, Pedagogia, Psicologia, Economia, ecc.,
        Ingegneri,
        Architetti,
        Biologi,
        Tecnologi Alimentari,
        Dottori in medicina,
        Infermieri,
        Avvocati,
        Informatici.
</p>

<p>
    <strong>
        FIGURE PROFESSIONALI - SETTORE SERVIZI:
    </strong><br>
        Tecnologi Alimentari,
        Ingegneri,
        Biologi,
        Agronomi,
        Ingegneri,
        Architetti,
        Dottori in medicina,
        Avvocati.
</p>

<p>Per tutti i settori di interesse sviluppiamo la nostra attività commerciale avvalendoci degli strumenti informatici e di segreteria.</p>

<p><strong>Profilo dei Responsabili</strong></p>

<p><strong>Dr. Antonello Paonessa</strong> (1976), Direttore e Legale Rappresentante della DaFor S.r.l., Tecnologo Alimentare, laureato in Scienze e Tecnologie Alimentari presso l’Università degli Studi di Napoli “Federico II”.</p>

<p><strong>Dr.ssa Casullo Antonella</strong> (1986), Responsabile Area Tecnica e del Settore Formazione, laureata in Scienze e Tecnologie Alimentari presso l’Università degli Studi della Basilicata.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Sede legale e operativa</h3>
                            <p>Via E. Montale 1<br>84091 Battipaglia (SA)</p>
                            <p>Telefono e Fax.: <a href="tel:+3908282301711">(+39) 0828-301711</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Sede operativa</h3>
                            <span class="text-muted">a 300 metri dalla Stazione Ferroviaria di Centola <br>a 3 Km dall'uscita di Poderia.</span>
                            <p>Via San. Margherita 13, 84051 - San Severino di Centola (SA)</p>
                            <p>Telefono : <a href="tel:+390974934343">(+39) 0974-934343</a><br>Fax : (+39) 0974-934700</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Sede operativa 2</h3>
                            <p>Via Sirene 33<br>84059 Marina di Camerota (SA)</p>
                            <p>Telefono e Fax.: <a href="tel:+390974379671">(+39) 0974-379671</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Sede operativa 3</h3>
                            <p>Strada provinciale 210<br>84079 Vibonati (SA)</p>
                            <p>Telefono <a href="tel:+390973301352">(+39) 0973-301352</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Telefono: <a href="tel:+393289742626">(+39) 0974-934343</a></p>
                            <p>WhatsApp: <a href="https://wa.me/+393939343433">(+39) 393-9343433</a></p>
                            <p>e-mail: <a href="mailto:info@dafor.it">info@dafor.it</a></p>
                            <p>PEC: <a href="mailto:daforsrl@pec.it">daforsrl@pec.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                            <p>Sabato: 09:00 - 13:00</p>
                        </li>
                    </ul>
                </div>
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}}); margin-top: 90px;">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

    </div>
</div>
