<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Prodotti</li>
            </ul>
            <h2>Sicurezza sul lavoro</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                      <p><span style="color:rgb(0, 0, 0)">La <strong><em>DAFOR S.r.l.</em></strong> assiste le Imprese nella scelta e nell’ acquisto di prodotti e tecnologie per assicurare la massima efficacia nel rispetto delle normative vigente sulla sicurezza del personale sul luogo di lavoro e sulla prevenzione incendi.</span></p>

                                    <p><span><span style="color:rgb(0, 0, 0)">Nello specifico fornisce:</span></span></p>

                                    <ul>
                                        <li><span><a><span style="color:#000000">Dispositivi di protezione individuale (DPI)</span></a><span style="color:#000000">;</span></span></li>
                                        <li><a><span><span style="color:rgb(0, 0, 0)">Mezzi estinguenti;</span></span></a></li>
                                        <li><a><span><span style="color:rgb(0, 0, 0)">Cartelli Segnaletici;</span></span></a></li>
                                        <li><a><span><span style="color:rgb(0, 0, 0)">Materiale di Pronto Soccorso.</span></span></a></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

