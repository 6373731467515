<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>Consulenza Sicurezza Aziendale</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="col-md-12">

                                    <article>
                                    <div class="testo" id="testo">
                                      <h2 style="text-align:justify"><span style="color:#000000">Consulenza sulla sicurezza aziendale ai sensi del D.Lgs. 81/08 (TESTO UNICO SICUREZZA)</span></h2>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">Il 30 aprile del 2008 è stato pubblicato sul supplemento ordinario n. 108/L della Gazzetta Ufficiale n. 101 il</span><a href="/public/files/D_Lgs_%2081_08-106_09-PQ.pdf"><span style="color:#000000"><strong> D.Lgs. n. 81 del 09/04/2008</strong></span></a><span style="color:#000000">. Quest'ultimo ha abrogato e sostituito il D.Lgs. 626/94, colonna portante della sicurezza sui luoghi del lavoro da oltre 15 anni.<br>
                                    Il nuovo </span><strong><a href="/public/files/D_Lgs_%2081_08-106_09-PQ.pdf"><span style="color:#000000">D.Lgs. 81/08</span></a></strong><span style="color:#000000">, non ha in alcun modo eliminato il vecchio Decreto sulla Sicurezza ma anzi lo ha integrato rendendolo ancora più completo, in quanto in tale testo sono riuniti tutte le normative in materia di sicurezza divenendo così il “TESTO UNICO SULLA SICUREZZA”.<br>
                                    <strong>Tra le principali novità del Testo Unico si segnalano: </strong></span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Applicazione delle norme sulla sicurezza a tutti i lavoratori, anche autonomi e parasubordinati;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Nuove modalità per svolgere la valutazione dei rischi che variano in base al numero dei lavoratori;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Rischio di sospensione dell'attività per le imprese che commettono gravi e reiterate violazioni;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Inasprimento delle sanzioni.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">La <strong>DAFOR S.r.l. </strong>offre assistenza e consulenza alle imprese sulla <strong>Sicurezza nei Lughi di Lavoro</strong>. L'evoluzione della normativa richiama l'imprenditore alla più ampia osservanza della sicurezza nell'esercizio dell'impresa. Un'assistenza qualificata, quale quella che la<strong><em> DAFOR S.r.l. </em></strong>&nbsp;può offrire, migliora i sistemi organizzativi per gestire e mantenere la sicurezza aziendale.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong><em>La nostra consulenza prevede: </em></strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Check-up aziendale</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Rilevazioni delle situazioni non conformi </strong>alla normativa vigente in materia di sicurezza e di igiene del lavoro relativamente a macchine, impianti, insediamenti e risorse umane; individuazione delle possibili soluzioni di intervento; consigli per l'effettuazione di ulteriori accertamenti e per l'approfondimento di eventuali problemi riscontrati.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Revisione o redazione del Documento di Valutazione dei Rischi (DVR)</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Sulla base dei dati rilevati nel check-up aziendale si procede all'individuazione dei pericoli e dei rischi conseguenti ed alla valutazione dei medesimi. A conclusione <strong>viene redatto, di concerto con l'azienda, il documento di valutazione dei rischi , che conterrà l'identificazione delle misure e/o cautele da prevedere ed il relativo piano di miglioramento.</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Mantenimento ed aggiornamento analisi rischi</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Quando contrattualmente definito, la<strong><em> DAFOR S.r.l.&nbsp; </em></strong>si impegna, attraverso visite periodiche e programmate, a mantenere costantemente aggiornato e revisionato il documento "Analisi dei Rischi" (nuovi impianti e/o attrezzature) e a verificare la corretta applicazione delle misure preventive o correttive scaturite dall'Analisi dei rischi precedenti.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Formazione del personale</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La<strong><em> DAFOR S.r.l.</em></strong>, quando richiesto, provvede direttamente o attraverso altre strutture qualificate, ad una formazione specifica, tesa alla prevenzione degli infortuni in linea con le indicazioni emerse dall'analisi dei rischi (inserimento nuovo personale o mobilità interna).</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Incarichi RSPP Esterni</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La <strong><em>DAFOR S.r.l.</em></strong>, mediante tecnici abilitati offre la possibilità di incarichi di Responsabile Servizio Prevenzione e Protezione Esterno (RSPP) per i Macrosettori (classificazione ATECO):<br>
                                    <strong>I</strong> (<em>Agricoltura</em>); <strong>II</strong> (<em>Pesca</em>); <strong>IV</strong> (<em>Industrie Alimentari, Legno, Tessili, Abbigliamento,Carta, Editoria</em>); <strong>VI</strong> (<em>Commercio ingrosso e dettaglio, Attività Artigianali, Trasporti, Magazzinaggi, Comunicazioni</em>); <strong>VII</strong> (<em>Sanità, Servizi sociali</em>); <strong>VIII</strong> (<em>Pubblica Amm.ne, Istruzione</em>); <strong>IX</strong> (<em>Alberghi, Ristoranti, Assicurazioni Immobiliari, Informatica, Ass.ni ricreative, culturali, sportive, Servizi domestici, Organizzi. Extraterrit.</em>)</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Elaborazione del Piano di emergenza</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Elaborazione del piano di emergenza o verifica di quello esistente. Analisi delle diverse problematiche relative ad incendi, valutazione di tutti gli aspetti tecnici, organizzativi e procedurali. Addestramento del personale.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Medicina del Lavoro – Incarico del Medico Competente</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La<strong><em> DAFOR S.r.l.</em></strong> si avvale di <strong>medici del lavoro</strong> (<strong><em>propri partners</em></strong>) per effettuare le visite <strong>mediche</strong> e gli <strong>accertamenti</strong> previsti dall’art. 41, comma 2, del </span><strong><a href="/public/files/D_Lgs_%2081_08-106_09-PQ.pdf"><span style="color:#000000">D.Lgs. 81/08</span></a></strong><span style="color:#000000"> per quanto riguarda la sicurezza dei lavoratori, e dall’ art. 14 della Legge 283/62 per il rilascio/rinnovo del L.I.S.A. (Libretto di Idoneità Sanitaria).</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Inoltre i medici del lavoro offrono la possibilità di essere nominati, dal datore di lavoro, come “<strong>Medico Competente</strong>” da quanto previsto dall’art. 39 del </span><strong><a href="/public/files/D_Lgs_%2081_08-106_09-PQ.pdf" target="_blank"><span style="color:#000000">D.Lgs. 81/08</span></a></strong><span style="color:#000000"> per la predisposizione dell'attuazione delle misure per la tutela della salute e dell'integrità psico-fisica dei lavoratori.</span></p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

