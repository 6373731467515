<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>Tracciabilità Filiera Produttiva</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">

                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                      <p style="text-align:justify"><strong><span style="font-size:16px"><span style="color:rgb(0, 0, 0)">Che cos'è&nbsp;&nbsp;</span></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                    <br>
                                    <span style="color:#000000">La certificazione di rintracciabilità di filiera agroalimentare, secondo la norma <strong>UNI 10939</strong> del 2001, attesta che, con ragionevole attendibilità, viene garantita e documentata la <strong>rintracciabilità</strong> del prodotto lungo <strong>tutte le fasi</strong> della sua elaborazione, <strong>coinvolgendo</strong> quindi <strong>tutti i soggetti della filiera</strong>.<br>
                                    Il controllo totale della filiera si ispira ai dettami del <strong>Libro Bianco</strong>, ma soprattutto risponde alla richiesta di trasparenza oggi sempre più pressante, coinvolgendo <strong>tutti gli elementi della catena</strong>: dal produttore primario al consumatore che in questo modo avrà gli strumenti per scegliere il prodotto più vicino alle proprie esigenze di qualità e di sicurezza, oltre che di soddisfazione del "gusto".<br>
                                    La <strong><em>Tracciabilità</em></strong> può essere definita come “la capacità di ricostruire la storia e di seguire l’utilizzo di un prodotto mediante identificazioni documentate concernenti sia i flussi di materiali che gli operatori di filiera”. Dal <strong>1° Gennaio 2005</strong> per le aziende di produzione agro-alimentare sarà obbligatoria l’adozione di un sistema di tracciabilità del prodotto. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    L’esigenza di garantire un elevato standard di sicurezza alimentare e di poter individuare tempestivamente la causa e l’origine di eventuali problemi, considerata la “destinazione d’uso” delle derrate alimentari, trova la sua soluzione nella strutturazione di un sistema che consenta di monitorare tutta la filiera produttiva fino alla commercializzazione.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    La <strong><em>tracciabilità</em></strong> rappresenta, pertanto, uno <strong>strumento per la garanzia della sicurezza alimentare</strong> e <strong>tutela del consumatore</strong> in ottemperanza alle normative dell’Unione Europea, finalizzata a:<br>
                                    a) garantire la sicurezza alimentare con la possibilità di intervenire in ogni momento, lungo tutta la filiera produttiva e distributiva;&nbsp;&nbsp;&nbsp;<br>
                                    b) individuare tutte le imprese che hanno concorso alla produzione di un prodotto, sia che venga utilizzato come materia prima o come prodotto intermedio o finito.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Iter di certificazione</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">L’attuazione di un progetto di tracciabilità, che responsabilizzi tutti i soggetti della filiera, deve passare attraverso:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    1) la <strong>realizzazione di strumenti</strong> (siano essi cartacei od informatici) in grado di far interagire tutte le componenti della filiera per la raccolta delle <strong>informazioni </strong>necessarie a realizzare ed agevolare i controlli a garanzia della sicurezza alimentare;&nbsp;<br>
                                    2) la<strong> modifica dei processi aziendali</strong> per consentire la tracciabilità di materie prime e prodotti lungo la filiera integrata;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    3) la <strong>definizione di strumenti finanziari </strong>a sostegno degli investimenti necessari a realizzare questo processo;<br>
                                    4) la <strong>definizione di specifiche procedure di controllo</strong>;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    5) la messa a punto di <strong>metodiche di autocontrollo</strong> da parte dei produttori al fine di ridurre i costi amministrativi e gestionali delle imprese e migliorare il rapporto con la pubblica amministrazione attraverso lo snellimento delle procedure.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    La DAFOR S.r.l. è in grado di sviluppare Sistemi di Rintracciabilità aziendale e di filiera, secondo quanto previsto dal </span><a href="/public/files/Reg%20CE%20178_02.pdf"><span style="color:#000000"><strong>Reg. CE 178/2002</strong></span></a><span style="color:#000000">, in conformità alle norme UNI 11020 ed UNI 10939, inoltre la DAFOR S.r.l. propone assistenza e consulenza per la realizzazione di “Manuali aziendali per la rintracciabilità”, sviluppati nel rispetto delle linee guida indicate dal </span><a href="http://www.politicheagricole.it/default.html" target="_blank"><span style="color:#000000">M.I.P.A.F.</span></a><span style="color:#000000"> (Ministero delle Politiche Agricole e Forestali) ed altri Istituti a carattere nazionale.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Vantaggi</strong></span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">La certificazione rappresenta un'ulteriore garanzia per l'acquirente perché gli si offre un prodotto alimentare con un processo gestito e controllato fin dall'origine e lungo tutta la filiera;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">la <strong>gestione per lotti</strong> permette di <strong>identificare</strong> in ogni momento i <strong>flussi materiali e le attività</strong> che hanno contribuito alla produzione del prodotto finito;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">la possibilità di <strong>richiamare agevolmente il prodotto</strong> in situazioni di emergenza, riuscendo a identificare e isolare l'anello responsabile, evitando che il sospetto si allarghi a tutti i soggetti coinvolti nell'ottenimento del prodotto;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">il <strong>miglioramento dei rapporti tra fornitori e intermediari</strong>, accomunati dalla condivisione dei principi della filiera;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">la promozione dell'<strong>immagine aziendale</strong>;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">le possibili <strong>sinergie</strong>, nel caso di verifiche congiunte, con la certificazione di <strong>sistemi gestionali</strong>.</span></li>
                                    </ul>

                                    <p>&nbsp;</p>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

