<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">Lavorare per apportare cambiamenti significativi nell'apprendimento online facendo ricerche approfondite per la preparazione del curriculum del corso, il coinvolgimento degli studenti e aspettando con impazienza l'istruzione flessibile!</p>
                    <ul class="social-link">
                        <li><a href="https://it-it.facebook.com/DaforSrl/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/ScuolaDafor" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCCG4RZeCNPFNGHu2hEVbnSg?view_as=subscriber" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/servizi-alimenti">Servizi</a></li>
                        <li><a routerLink="/par-gol">PAR GOL</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>&nbsp;</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/prodotti">Prodotti</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via E. Montale 1<br>84091 - Battipaglia (SA) Italia</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390828301711">Telefono: (+39) 0828-301711</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@dafor.it">info@dafor.it</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:daforsrl@pec.it">daforsrl@pec.it</a></li>
                        <li><i class='bx bxs-inbox'></i>Lunedì-Venerdì: 09:00÷18:00<br>Sabato: 09:00÷13:00</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>{{AnnoAttuale}} Dafor S.r.l - realizzato con <a style="color: #ffe44c;" target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
