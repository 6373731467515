<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Prodotti</li>
            </ul>
            <h2>Prodotti</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div>

                                    <h5 style="text-align:justify"><span style="color:#000000"><span><span><strong>Punto Qualità è la Divisione di DAFOR S.r.l. che offre servizi di sanificazione degli ambienti e controllo infestanti &nbsp;tramite una rete di vendita di prodotti di pulizia, igiene ambientale, materiali di consumo, strumenti di controllo e software.</strong></span></span></span></h5>

                                    <p style="text-align:justify"><span style="color:#000000"><span><span >I servizi di igiene Ambientale rappresentano sempre una criticità per le Imprese, soprattutto quelle alimentari.<br>
                                    Il nostro servizio nasce dall’esperienza maturata come consulenti della qualità e della sicurezza <strong>Alimentare</strong> ed è in grado di eseguire programmi di lotta mirati, adeguando tutto il servizio di derattizzazione e disinfestazione alle esigenze poste dall’Autocontrollo e dal Sistema Qualità adottato.</span></span></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><span><span >In particolare la Divisione <strong>PUNTO QUALITÀ</strong> fornisce alle <strong>Imprese Alimentari </strong>prodotti e materiali per la Pulizia degli Ambienti di Lavoro, prodotti per la Disinfestazione e prodotti chimici per l’igiene e sicurezza delle piscine.</span></span></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><span><span>Inoltre questa Divisione fornisce a qualsiasi Azienda prodotti per la Sicurezza sul Lavoro secondo quanto dettato dal </span></span></span><span><span><a><span style="color:#000000"><strong>D.Lgs. 81/08</strong></span></a></span></span><span style="color:#000000"><span><span> ed in particolare: Dispositivi di Protezione Individuale (DPI) – Estintori – Cartelli Segnaletici e Materiali di Pronto Soccorso.</span></span></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><span><span>Infine la Divisione può fornire l'intera gamma degli <strong>Strumenti e Software</strong> necessari alla gestione dell'attività di monitoraggio di parametri ambientali ed alimentari.</span></span></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><span><span><strong>A lato sono elencati le varie categorie di prodotti che si offrono.</strong></span></span></span></p>









                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

