<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a href="prodotti">Prodotti</a></li>
            </ul>
            <h2>Igiene Ambientale</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="col-md-12">

                                    <article>

                                    <div class="testo" id="testo">
                                      <p><span style="color:#000000">I prodotti per l'IGIENE AMBIENTALE si dividono in tre classi:</span></p>

                                    <ul>
                                        <li><a href="prodotti-materiali"><span style="color:#000000"><strong>Prodotti e Materiali per la Pulizia;</strong></span></a></li>
                                        <li><a href="prodotti-disinfestazione"><span style="color:#000000"><strong>Prodotti per la Disinfestazione;</strong></span></a></li>
                                        <li><a href="prodotti-piscine"><span style="color:#000000"><strong>Prodotti Chimici per le Piscine.</strong></span></a></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

