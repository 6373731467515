<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>Rilievi Fonometrici nei Luoghi di Lavoro</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                    <p style="text-align:justify"><span style="color:#000000">Qui di seguito sono riportati, in maniera sintetica, i servizi erogati dalla <strong><em>DAFOR. S.r.l. </em></strong>per l’ attività <strong>di Valutazione del Rumore nei luoghi di lavoro</strong>:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><strong><span style="font-size:14px">Predisposizione del Documento di Valutazione dei Rischi da Rumore</span>&nbsp; </strong><strong><em>(Titolo VIII Capo II del D.Lgs. 81/08 (ex D.Lgs.&nbsp; 195/06))</em></strong></span></li>
                                    </ul>

                                    <p style="margin-left:54pt; text-align:justify"><span style="color:#000000">L'espletamento dell'incarico consiste in:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">sopralluogo in sito;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">rilievo fonometrico (mediante Fonometro integratore di classe I) delle attività, macchine ed impianti rumorosi;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">individuazione dei gruppi omogenei e/o dei lavoratori esposti;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">ricostruzione del ciclo produttivo;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">calcolo del livello equivalente di esposizione giornaliero (Lep, d) o settimanale (Lep, w) mediante apposito software;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">stesura del documento e relative indicazioni tecniche.</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Realizzazione dell'informazione dei lavoratori;</strong></span></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Informazione dei lavoratori sui danni causati dal rumore, sulle misure di prevenzione e protezione, sul significato dell'indagine fonometrica, l'informazione sarà documentata mediante apposito verbale di riunione, sottoscritto anche dai lavoratori;</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Realizzazione dell'addestramento e formazione all'uso dei DPI;</strong></span></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Predisposizione di apposito modulo formativo finalizzato ad un corretto uso dei Dispositivi di Protezione Individuale (cuffie, tappi auricolari, inserti) ed addestramento all'uso degli stessi, l'addestramento sarà documentato mediante apposito verbale, sottoscritto dai lavoratori;</span></p>

                                    <p style="margin-left:18pt; text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Consultazione in merito alla scelta, distribuzione e conservazione dei DPI;</strong></span></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Predisposizione di apposita procedura, correlata da idonea modulistica, finalizzata ad una corretta gestione dei Dispositivi di Protezione Individuale, scelta, distribuzione, conservazione, manutenzione e sostituzione degli otoprotettori.</span></p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

