import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ElearningSchoolComponent } from './components/pages/elearning-school/elearning-school.component';
import { VendorCertificationEtrainingComponent } from './components/pages/vendor-certification-etraining/vendor-certification-etraining.component';
import { OnlineTrainingSchoolComponent } from './components/pages/online-training-school/online-training-school.component';
import { DistanceLearningComponent } from './components/pages/distance-learning/distance-learning.component';
import { LanguageSchoolComponent } from './components/pages/language-school/language-school.component';
import { ModernSchoolingComponent } from './components/pages/modern-schooling/modern-schooling.component';
import { YogaTrainingComponent } from './components/pages/yoga-training/yoga-training.component';
import { HealthCoachingComponent } from './components/pages/health-coaching/health-coaching.component';
import { KindergartenComponent } from './components/pages/kindergarten/kindergarten.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { AboutStyleOneComponent } from './components/pages/about-style-one/about-style-one.component';
import { AboutStyleTwoComponent } from './components/pages/about-style-two/about-style-two.component';
import { AboutStyleThreeComponent } from './components/pages/about-style-three/about-style-three.component';
import { AboutStyleFourComponent } from './components/pages/about-style-four/about-style-four.component';
import { SuccessStoryComponent } from './components/pages/success-story/success-story.component';
import { TeacherComponent } from './components/pages/teacher/teacher.component';
import { ProfileAuthenticationComponent } from './components/pages/profile-authentication/profile-authentication.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { PurchaseGuideComponent } from './components/pages/purchase-guide/purchase-guide.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { CoursesStyleOneComponent } from './components/pages/courses-style-one/courses-style-one.component';
import { CoursesStyleTwoComponent } from './components/pages/courses-style-two/courses-style-two.component';
import { CoursesStyleThreeComponent } from './components/pages/courses-style-three/courses-style-three.component';
import { CoursesStyleFourComponent } from './components/pages/courses-style-four/courses-style-four.component';
import { CoursesStyleFiveComponent } from './components/pages/courses-style-five/courses-style-five.component';
import { CoursesStyleSixComponent } from './components/pages/courses-style-six/courses-style-six.component';
import { CoursesStyleSevenComponent } from './components/pages/courses-style-seven/courses-style-seven.component';
import { CoursesDetailsStyleOneComponent } from './components/pages/courses-details-style-one/courses-details-style-one.component';
import { CoursesDetailsStyleTwoComponent } from './components/pages/courses-details-style-two/courses-details-style-two.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { ProfileQuizComponent } from './components/pages/profile-quiz/profile-quiz.component';
import { MembershipLevelsComponent } from './components/pages/membership-levels/membership-levels.component';
import { BecomeATeacherComponent } from './components/pages/become-a-teacher/become-a-teacher.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { EventsComponent } from './components/pages/events/events.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { ProductsListStyleOneComponent } from './components/pages/products-list-style-one/products-list-style-one.component';
import { ProductsListStyleTwoComponent } from './components/pages/products-list-style-two/products-list-style-two.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { BlogStyleOneComponent } from './components/pages/blog-style-one/blog-style-one.component';
import { BlogStyleTwoComponent } from './components/pages/blog-style-two/blog-style-two.component';
import { BlogStyleThreeComponent } from './components/pages/blog-style-three/blog-style-three.component';
import { BlogStyleFourComponent } from './components/pages/blog-style-four/blog-style-four.component';
import { BlogStyleFiveComponent } from './components/pages/blog-style-five/blog-style-five.component';
import { BlogDetailsStyleOneComponent } from './components/pages/blog-details-style-one/blog-details-style-one.component';
import { BlogDetailsStyleTwoComponent } from './components/pages/blog-details-style-two/blog-details-style-two.component';
import { BlogDetailsStyleThreeComponent } from './components/pages/blog-details-style-three/blog-details-style-three.component';
import { GymCoachingComponent } from './components/pages/gym-coaching/gym-coaching.component';
import { LearningManagementComponent } from './components/pages/learning-management/learning-management.component';

import { ReadComponent } from './components/products/read/read.component';
import { DetailComponent } from './components/products/detail/detail.component';
import { ValiditaAttestatoComponent } from './components/pages/validita-attestato/validita-attestato';
import { Eipass } from './components/pages/eipass/eipass';
import { Pekit } from './components/pages/pekit/pekit';
import { LIM } from './components/pages/lim/lim';
import { Inglese } from './components/pages/inglese/inglese';
import { Coding } from './components/pages/coding/coding';
import { Tablet } from './components/pages/tablet/tablet';
import { ParGol } from './components/pages/pargol/par-gol';
import { GestioneAutonoma } from './components/pages/gestione-autonoma/gestione-autonoma';
import { JitsiComponent } from './jitsi/jitsi.component';

import { ServiziAlimenti } from './components/pages/servizi-alimenti/servizi-alimenti';
import { ServiziAlimentiHaccp } from './components/pages/servizi-alimenti/servizi-alimenti-haccp';
import { ServiziAlimentiBollinoBlu } from './components/pages/servizi-alimenti/servizi-alimenti-bollino-blu';
import { ServiziAlimentiAnalisi } from './components/pages/servizi-alimenti/servizi-alimenti-analisi';
import { ServiziAlimentiTracciabilita } from './components/pages/servizi-alimenti/servizi-alimenti-tracciabilita';
import { ServiziAlimentiTecnologie } from './components/pages/servizi-alimenti/servizi-alimenti-tecnologie';
import { ServiziAlimentiRilievi } from './components/pages/servizi-alimenti/servizi-alimenti-rilievi';
import { ServiziAlimentiIso22000 } from './components/pages/servizi-alimenti/servizi-alimenti-iso22000';
import { ServiziAlimentiAssistenza } from './components/pages/servizi-alimenti/servizi-alimenti-assistemza';
import { ServiziAlimentiAssistenzaLegale } from './components/pages/servizi-alimenti/servizi-alimenti-assistemza-legale';

import { ServiziSicurezza } from './components/pages/servizi-sicurezza/servizi-sicurezza';
import { ServiziSicurezzaConsulenza } from './components/pages/servizi-sicurezza/servizi-sicurezza-consulenza';
import { ServiziSicurezzaSorveglianza } from './components/pages/servizi-sicurezza/servizi-sicurezza-sorveglianza';
import { ServiziSicurezzaFonometrici } from './components/pages/servizi-sicurezza/servizi-sicurezza-fonometrici';
import { ServiziSicurezzaVibrazionali } from './components/pages/servizi-sicurezza/servizi-sicurezza-vibrazionali';
import { ServiziSicurezzaMicro } from './components/pages/servizi-sicurezza/servizi-sicurezza-micro';
import { ServiziSicurezzaIncendiLavoro } from './components/pages/servizi-sicurezza/servizi-sicurezza-incendi-lavoro';
import { ServiziSicurezzaIgienePiscina } from './components/pages/servizi-sicurezza/servizi-sicurezza-igiene-piscina';
import { ServiziSicurezzaPrevenzione } from './components/pages/servizi-sicurezza/servizi-sicurezza-prevenzione';
import { ServiziSicurezzaOHSAS } from './components/pages/servizi-sicurezza/servizi-sicurezza-ohsas';

import { ServiziQuyalita } from './components/pages/servizi-qualita/servizi-qualita';
import { ServiziQuyalitaIso9001 } from './components/pages/servizi-qualita/servizi-qualita-iso9001';
import { ServiziQuyalitaIso14001 } from './components/pages/servizi-qualita/servizi-qualita-iso14001';
import { ServiziQuyalitaCE } from './components/pages/servizi-qualita/servizi-qualita-ce';

import { ServiziAmbiente } from './components/pages/servizi-ambiente/servizi-ambiente';
import { ServiziAmbienteSanificazione } from './components/pages/servizi-ambiente/servizi-ambiente-sanificazione';
import { ServiziAmbienteConsulenza } from './components/pages/servizi-ambiente/servizi-ambiente-consulenza';
import { ServiziAmbienteRIlievi } from './components/pages/servizi-ambiente/servizi-ambiente-rilievi';
import { ServiziAmbienteRadon } from './components/pages/servizi-ambiente/servizi-ambiente-radon';

import { ServiziLicenze } from './components/pages/servizi-licenze/servizi-licenze';
import { ServiziEdilizia } from './components/pages/servizi-edilizia/servizi-edilizia';

import { Prodotti } from './components/pages/prodotti/prodotti';
import { ProdottiAmbientale } from './components/pages/prodotti/prodotti-ambientale';
import { ProdottiMateriali } from './components/pages/prodotti/prodotti-materiali';
import { ProdottiDisinfestazione } from './components/pages/prodotti/prodotti-disinfenstazione';
import { ProdottiPiscine } from './components/pages/prodotti/prodotti-piscine';
import { ProdottiSicrezzaLavoro } from './components/pages/prodotti/sicurezza-lavoro';
import { ProdottiSicurezzaStrumenti } from './components/pages/prodotti/sicurezza-strumenti';

const routes: Routes = [
    {path: '', component: ElearningSchoolComponent},
    {path: '', component: JitsiComponent},
    //Interfacce con API PHP
    {path: 'elenco-corsi', component: ReadComponent},
    {path: 'elenco-corsi/:id', component: ReadComponent},
    {path: 'dettaglio-corso/:id', component: DetailComponent},
    {path: 'validita-attestati', component: ValiditaAttestatoComponent},
    {path: 'eipass', component: Eipass},
    {path: 'par-gol', component: ParGol},
    {path: 'pekit', component: Pekit},
    {path: 'lim', component: LIM},
    {path: 'inglese', component: Inglese},
    {path: 'coding', component: Coding},
    {path: 'tablet', component: Tablet},
    {path: 'corsisti/:id', component: GestioneAutonoma},

    {path: 'servizi-alimenti', component: ServiziAlimenti},
    {path: 'servizi-alimenti-haccp', component: ServiziAlimentiHaccp},
    {path: 'servizi-alimenti-bollino-blu', component: ServiziAlimentiBollinoBlu},
    {path: 'servizi-alimenti-analisi', component: ServiziAlimentiAnalisi},
    {path: 'servizi-alimenti-tracciabilita', component: ServiziAlimentiTracciabilita},
    {path: 'servizi-alimenti-tecnologie', component: ServiziAlimentiTecnologie},
    {path: 'servizi-alimenti-rilievi', component: ServiziAlimentiRilievi},
    {path: 'servizi-alimenti-iso22000', component: ServiziAlimentiIso22000},
    {path: 'servizi-alimenti-assistenza', component: ServiziAlimentiAssistenza},
    {path: 'servizi-alimenti-assistenza-legale', component: ServiziAlimentiAssistenzaLegale},

    {path: 'servizi-sicurezza', component: ServiziSicurezza},
    {path: 'servizi-sicurezza-consulenza', component: ServiziSicurezzaConsulenza},
    {path: 'servizi-sicurezza-sorveglianza', component: ServiziSicurezzaSorveglianza},
    {path: 'servizi-sicurezza-fonometrici', component: ServiziSicurezzaFonometrici},
    {path: 'servizi-sicurezza-vibrazionali', component: ServiziSicurezzaVibrazionali},
    {path: 'servizi-sicurezza-micro', component: ServiziSicurezzaMicro},
    {path: 'servizi-sicurezza-incendi-lavoro', component: ServiziSicurezzaIncendiLavoro},
    {path: 'servizi-sicurezza-igiene-piscina', component: ServiziSicurezzaIgienePiscina},
    {path: 'servizi-sicurezza-prevenzione', component: ServiziSicurezzaPrevenzione},
    {path: 'servizi-sicurezza-ohsas', component: ServiziSicurezzaOHSAS},

    {path: 'servizi-qualita', component: ServiziQuyalita},
    {path: 'servizi-qualita-iso9001', component: ServiziQuyalitaIso9001},
    {path: 'servizi-qualita-iso14001', component: ServiziQuyalitaIso14001},
    {path: 'servizi-qualita-ce', component: ServiziQuyalitaCE},

    {path: 'servizi-ambiente', component: ServiziAmbiente},
    {path: 'servizi-ambiente-sanificazione', component: ServiziAmbienteSanificazione},
    {path: 'servizi-ambiente-consulenza', component: ServiziAmbienteConsulenza},
    {path: 'servizi-ambiente-rilievi', component: ServiziAmbienteRIlievi},
    {path: 'servizi-ambiente-radon', component: ServiziAmbienteRadon},

    {path: 'servizi-licenze', component: ServiziLicenze},
    {path: 'servizi-edilizia', component: ServiziEdilizia},

    {path: 'prodotti', component: Prodotti},
    {path: 'prodotti-ambientale', component: ProdottiAmbientale},
    {path: 'prodotti-materiali', component: ProdottiMateriali},
    {path: 'prodotti-disinfestazione', component: ProdottiDisinfestazione},
    {path: 'prodotti-piscine', component: ProdottiPiscine},
    {path: 'prodotti-sicurezza', component: ProdottiSicrezzaLavoro},
    {path: 'prodotti-misura', component: ProdottiSicurezzaStrumenti},

    {path: 'vendor-certification-etraining', component: VendorCertificationEtrainingComponent},
    {path: 'online-training-school', component: OnlineTrainingSchoolComponent},
    {path: 'distance-learning', component: DistanceLearningComponent},
    {path: 'language-school', component: LanguageSchoolComponent},
    {path: 'modern-schooling', component: ModernSchoolingComponent},
    {path: 'yoga-training', component: YogaTrainingComponent},
    {path: 'health-coaching', component: HealthCoachingComponent},
    {path: 'kindergarten', component: KindergartenComponent},
    {path: 'gym-coaching', component: GymCoachingComponent},
    {path: 'learning-management', component: LearningManagementComponent},
    {path: 'about-1', component: AboutStyleOneComponent},
    {path: 'about-2', component: AboutStyleTwoComponent},
    {path: 'about-3', component: AboutStyleThreeComponent},
    {path: 'about-4', component: AboutStyleFourComponent},
    {path: 'success-story', component: SuccessStoryComponent},
    {path: 'teacher', component: TeacherComponent},
    {path: 'reset-password', component: ProfileAuthenticationComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'purchase-guide', component: PurchaseGuideComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'cookie-policy', component: TermsOfServiceComponent},
    {path: 'courses-1', component: CoursesStyleOneComponent},
    {path: 'courses-2', component: CoursesStyleTwoComponent},
    {path: 'courses-3', component: CoursesStyleThreeComponent},
    {path: 'courses-4', component: CoursesStyleFourComponent},
    {path: 'courses-5', component: CoursesStyleFiveComponent},
    {path: 'courses-6', component: CoursesStyleSixComponent},
    {path: 'courses-7', component: CoursesStyleSevenComponent},
    {path: 'single-courses-1', component: CoursesDetailsStyleOneComponent},
    {path: 'single-courses-2', component: CoursesDetailsStyleTwoComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'profile-quiz', component: ProfileQuizComponent},
    {path: 'membership-levels', component: MembershipLevelsComponent},
    {path: 'become-a-teacher', component: BecomeATeacherComponent},
    {path: 'categories', component: CategoriesComponent},
    {path: 'events', component: EventsComponent},
    {path: 'single-events', component: EventsDetailsComponent},
    {path: 'products-list-1', component: ProductsListStyleOneComponent},
    {path: 'products-list-2', component: ProductsListStyleTwoComponent},
    {path: 'cart', component: CartComponent},
    {path: 'checkout', component: CheckoutComponent},
    {path: 'single-products', component: ProductsDetailsComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'blog-1', component: BlogStyleOneComponent},
    {path: 'blog-2', component: BlogStyleTwoComponent},
    {path: 'blog-3', component: BlogStyleThreeComponent},
    {path: 'blog-4', component: BlogStyleFourComponent},
    {path: 'blog-5', component: BlogStyleFiveComponent},
    {path: 'single-blog-1', component: BlogDetailsStyleOneComponent},
    {path: 'single-blog-2', component: BlogDetailsStyleTwoComponent},
    {path: 'single-blog-3', component: BlogDetailsStyleThreeComponent},
    {path: 'contatti', component: ContactUsComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
