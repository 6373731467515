<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>

            </ul>
            <h2>Licenze</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/servizi/servizi-licenze.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">


                                <div class="col-md-12">

                                    <article>

                                    <div class="testo" id="testo">
                                      <p style="text-align:justify"><span style="color:#000000"><span style="font-size:22px"><strong>Licenze e Autorizzazioni&nbsp;</strong></span></span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Vuoi aprire una nuova attività commerciale?</strong> L’aspetto <strong>licenze e autorizzazioni</strong> è indispensabile prima dell’<strong>apertura di qualsiasi attività commerciali </strong>per evitare sanzioni o addirittura la notifica di chiusura da parte delle autorità competenti.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Punto Qualità </strong>(<em>DAFOR &nbsp;S.r.l.</em>) <strong>è l’interlocutore </strong>di riferimento per aiutare qualsiasi imprenditore nella stesura corretta delle documentazioni previste per legge e le relative presentazioni delle documentazioni agli sportelli comunali o autorità competenti (<strong>SUAP, ASL, Vigili del Fuoco, Sovrintendenza, Municipi, Dipartimenti comunali, etc)</strong>.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Di seguito i principali servizi di consulenza e supporto per le aperture di attività commerciali:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><u><strong><a><span style="color:#0000FF">PRESENTAZIONE DIA/SCIA SANITARIA</span></a></strong></u><span style="color:#000000"><strong> </strong><br>
                                        <em>Ex Autorizzazione Sanitaria per l'apertura di un locale pubblico (bar, ristoranti, etc) e per tutte le attività che manipolano gli alimenti.</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>PRESENTAZIONE SCIA SOMMINISTRAZIONI ALIMENTARI </strong><br>
                                        <em>Licenza commerciale per la somministrazione di alimenti e bevande (bar, ristoranti, gastronomia, pizzeria, etc)</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>PRESENTAZIONE SCIA DI ESERCIZI DI VICINATO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                        <em>Licenza commerciale per la vendita di alimenti e non (frutta e verdura, alimentari, panetteria, macelleria, pescheria, minimarket, gelateria, &nbsp;ferramenta, abbigliamento, articoli da regalo, casalinghi, etc)</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>PRESENTAZIONE SCIA ALBERGHIERA ED EXTRA ALBERGHIERA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                        <em>Autorizzazione per l’apertura di attività nel settore turistico/ricettivo (Affittacamere imprenditoriali e non, bed and breakfast, agriturismi, residence, country house, casa vacanze, etc)</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>PRESENTAZIONE SCIA LABORATORI &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                        <em>Autorizzazione per aperture attività artigianali (meccanici, gommisti, elettrauto, carrozzeria, acconciatore, centro estetico, centri benessere, laboratorio pizzeria a taglio, etc)</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>PRESENTAZIONE SCIA DEPOSITI ALIMENTARI E NON</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                        <em>Autorizzazione per deposito delle merci (qualsiasi deposito per merce di tipo alimentare e non alimentare)</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>PRESENTAZIONE SCIA PER AGENZIA DI AFFARI</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                        <em>Autorizzazione per la vendita prodotti e servizi conto terzi e intermediazioni (agenzia usato usato, agenzia immobiliari, etc)</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>PRESENTAZIONE SCIA ANTINCENDIO (ex CPI)&nbsp;&nbsp; </strong><br>
                                        <em>Autorizzazioni legate alla normativa antincendio. (Autorimesse, Industria, Condomini, etc)</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>PRESENTAZIONE PRATICHE VIDEOSORVEGLANZA&nbsp;&nbsp; </strong><br>
                                        <em>Autorizzazione per l'installazione di impianti di video sorveglianza. Sono soggetti alla richiesta di Autorizzazione tutte le imprese che hanno un impianto di videosorveglianza con registrazione e/o visione delle immagini. </em></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">L'espletamento dell'incarico consiste in:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Sopralluogo in sito</strong>;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Elaborazione delle documentazioni; </strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Presentazione delle pratiche agli organi competenti e terrritoriali;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Formazone e Assistenza; </strong></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">&nbsp;Il Nostro <strong>staff</strong>, composto da <strong>Ingegneri e Architetti abilitati e iscritti ai rispettivi albi</strong>, si pone come valido interlocutore per le diverse attività di analisi, progettazione e presentazioni delle autorizzazioni.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>



                                    </div>
                                    </article>







                                      </div>





                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

