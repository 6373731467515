<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>
            </ul>
            <h2>Analisi degli Alimenti</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">

                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">

                                    <p style="text-align:justify"><span style="color:#000000"><strong><em>La DAFOR S.r.l.</em></strong> esegue servizi di campionamento e analisi, sia chimiche che microbiologiche, su bevande, matrici alimentari e su ambienti di lavoro supportando il cliente nell’affrontare le problematiche collegate alle produzioni alimentari. Le Analisi vengono effettuate presso laboratori (nostri servers) certificati dai ministeri competenti.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">In particolare effettuiamo:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Campionamento e analisi delle acque destinate al consumo umano per verificarne le caratteristiche chimico-fisiche, microbiologiche e di potabilità ai sensi della normativa vigente (</span><a><span style="color:#000000"><strong><em>Tab. A del Allegato II del D.Lgs. 31/2001</em></strong></span></a><span style="color:#000000">).</span></li>
                                    </ul>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Campionamento ed esecuzione di analisi microbiologiche e chimico-fisiche di alimenti, materie prime, intermedi di produzione e prodotti finiti finalizzate alla verifica della conformità secondo le norme vigenti ed alla ottimizzazione dei processi produttivi.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Infatti le metodiche analitiche vengono applicate all’intera gamma dei microrganismi del settore agroalimentare:</span></p>

                                    <ul style="list-style-type:circle">
                                        <li style="text-align:justify"><span style="color:#000000">Ricerca dei microrganismi patogeni (<em>Salmonella, Listeria, Vibrio, Campylobacter, Clostridium Botulinum, Yersinia, etc.</em>) in alimenti, materie prime, intermedi di produzione e tamponi ambientali.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Definizione dei parametri indice e indicatori di contaminazione (<em>Carica Microbica Totale, Coliformi termotolleranti, Escherichia Coli, Stafilococchi coagulasi positivi, etc.</em>).</span></li>
                                    </ul>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Campionamenti ed analisi degli effluenti gassosi convogliati in atmosfera per la determinazione delle sostanze inquinanti derivanti dalle attività industriali ed artigianali;</span></li>
                                    </ul>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Analisi sul posto mediante <strong>Strumentazione Portatile</strong> per il monitoraggio di: temperatura, umidità, velocità dell’aria, pressione, intensità luminosa (<em>Luxmetro</em>), CO<sub>2</sub>, pH, analisi acqua, analisi olio, ecc…</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p>&nbsp;</p>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

