<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a href="prodotti">Prodotti</a></li>
                <li><a href="prodotti-ambientale">Igiene Ambientale</a></li>
            </ul>
            <h2>Prodotti per piscine</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                      <h1 style="text-align: justify;"><span style="color:#000000"><strong><span style="font-size:18px">PRODOTTI CHIMICI PER PISCINE</span></strong>&nbsp;</span></h1>

                                    <p style="text-align: justify;"><span style="color:#000000">Visto la consulenza che la <strong><em>DAFOR S.r.l.</em></strong> offre nel campo dell’igiene e della sicurezza negli impianti natatori&nbsp; e vista la richiesta dei propri clienti, vengono forniti a chi lo richiede prodotti chimici per impianti natatori e precisamente:</span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000">Prodotti per la clorazione;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Correttori di pH;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Antialghe;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Flocculanti;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Prodotti detergenti per pulizia delle vasche;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Pulitori idraulici e/o automatici;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Kit controllo pH e cloro;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Ecc…</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>


                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

