<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Cookie Policy</li>
            </ul>
            <h2>Cookie Policy</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="terms-of-service-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">
                    <div class="post-content">
        <h3>Tipologia di dati trattati</h3>
        <span class="fw-bold">Dati di navigazione</span>
        <p>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.</p>
        <p>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.</p>
        <p>Saranno raccolti i dati di navigazione esclusivamente nell’interesse legittimo di permettere all’utente la fruizione dei contenuti pubblicati sui siti Internet del Titolare ed una loro corretta amministrazione e gestione. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati in questione potrebbero essere utilizzati per l’accertamento di responsabilità in caso di eventuali reati informatici ai danni del nostro sito: salva questa eventualità, in genere i dati sui contatti web non vengono conservati per più di sette giorni.</p>
        <span class="fw-bold">Cookies</span>
        <p>I cookies sono piccoli file di testo che i siti visitati inviano al terminale dell’Utente, dove vengono memorizzati, per poi essere ritrasmessi agli stessi siti alla visita successiva.
        In conformità con quanto disposto dal Provvedimento generale del Garante per la Protezione dei Dati Personali dell’8 maggio 2014, si informano gli utenti sulle modalità di gestione dei cookie del presente sito.</p>
        <p>Nessun dato personale degli utenti viene in proposito acquisito dal sito.&nbsp;Non viene fatto uso di cookies per la trasmissione di informazioni di carattere personale, nè vengono utilizzati c.d. cookies di sessione o cookies persistenti di alcun tipo, e tantomeno cookie di profilazione. Il Content Management System utilizzato da dafor.it potrebbe rilasciare cookie tecnici indispensabili alla corretta visualizzazione o navigazione del sito.</p>
        <p>dafor.it, tramite la funzione <em>Condividi questo articolo</em>, mette a disposizione degli utenti pulsanti per la condivisione dei contenuti di dafor.it tramite social network o altri strumenti di comunicazione. Le pagine che contengono tali pulsanti non rilasciano cookie né di dafor.it né di terza parte. Tuttavia, come ovvio, cliccando il simbolo dello strumento di condivisione prescelto l’utente accederà a funzionalità o piattaforme di terze parti – di cui il Titolare di dafor.it non è in alcun modo responsabile – che potrebbero rilasciare cookie e raccogliere dati personali.</p>
        <span class="fw-bold">Dati forniti volontariamente dall’utente</span>
        <p>L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi con estensione <b>@dafor.it</b> indicati su questo sito comporta per sua stessa natura la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva. Le basi giuridiche del trattamento sono, pertanto, il legittimo interesse del Titolare di rispondere alle comunicazioni pervenute o la necessità di evadere richieste precontrattuali avanzate dall’interessato. I dati saranno conservati per il tempo necessario a soddisfare eventuali richieste del mittente o questioni ivi sottoposte al Titolare e, comunque, per il tempo imposto da specifiche disposizioni di legge. Il mittente ha comunque, bene ricordarlo, il diritto di chiedere la cancellazione degli stessi secondo le modalità, condizioni e limiti previsti dall’art. 17 del GDPR.</p>
        <p>Invitiamo i nostri utenti a non inviare nelle loro comunicazioni nominativi o altri dati personali di soggetti terzi che non siano strettamente necessari; ben più opportuno appare, invece, l’uso di nomi di fantasia.</p>
        <h3>Facoltatività del conferimento dei dati</h3>
        <p>A parte quanto specificato per i dati di navigazione, l’utente è libero di fornire spontaneamente dati personali per richiedere informazioni o servizi.&nbsp;Il loro mancato conferimento può comportare l’impossibilità di ottenere quanto richiesto.</p>
        <h3>Modalità del trattamento</h3>
        <p>I dati personali sono trattati in formato elettronico, per il tempo necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.</p>
        <h3>Diritti degli interessati</h3>
        <p>Ai sensi degli articoli 15 e seguenti del GDPR, l’utente ha il diritto di chiedere in qualunque momento, l’accesso ai suoi dati personali, la rettifica o la cancellazione degli stessi, la limitazione del trattamento nei casi previsti dall’art. 18 del Regolamento, ottenere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati che lo riguardano, nei casi previsti dall’art. 20 del GDPR. In ogni momento, l’utente può revocare ex art. 7 del GDPR il consenso prestato (laddove il consenso sia stato richiesto); proporre reclamo all’autorità di controllo competente ex art. 77 del GDPR (Garante per la Protezione dei Dati Personali), qualora ritenga che il trattamento dei suoi dati sia contrario alla normativa in vigore.</p>
        <p>L’utente può, inoltre, formulare una richiesta di opposizione al trattamento dei suoi dati personali ex art. 21 del GDPR nella quale dare evidenza delle ragioni che giustifichino l’opposizione: il Titolare si riserva di valutare l’istanza, che potrebbe non essere accettata laddove sussistano obblighi o motivi legittimi prevalenti sugli interessi, diritti e libertà dell’utente e che impongano o giustifichino la prosecuzione del trattamento.</p>
        <p>Le richieste inerenti l’esercizio dei summenzionati diritti devono essere rivolte all’attenzione dell’amministratore di Dafor Srl con sede in Via E. Montale, 1 – 84091 Battipaglia (SA) oppure all’indirizzo e-mail: info@dafor.it</p>
        <h3>Aggiornamenti della presente Privacy e Cookie Policy</h3>
        <p>Le notizie qui rese possono essere soggette a revisione in seguito a: modifiche della normativa di privacy, per gli aspetti qui di interesse; implementazioni tecnologiche del sito che impattino sulle attuali modalità di trattamento; variazioni organizzative nella struttura di privacy del Titolare che possano interessare l’utente.</p>
        <p>Gli utenti sono cortesemente invitati a visitare periodicamente la presente Policy di modo da essere costantemente aggiornati sulle caratteristiche del trattamento.</p>

                </div>
            </div>
        </div>
    </div>
</div>
