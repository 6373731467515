<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>Rilievi Micro-Climatici</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">

                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">

                                    <p style="text-align: justify;"><span style="color:#000000">Fra i diversi fattori che incidono sulla qualità degli ambienti di vita e di lavoro, il microclima, ovvero il complesso dei parametri ambientali che condizionano lo scambio termico soggetto - ambiente, riveste un'importanza determinante.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">Infatti il conseguimento del benessere termico, cioè lo stato di piena soddisfazione nei confronti dell'ambiente stesso, costituisce per l'uomo una condizione indispensabile e prioritaria per il raggiungimento del benessere totale.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">Assume pertanto rilevanza la valutazione dell'ambiente termico in cui l'uomo si trova ad operare. I fattori oggettivi ambientali da valutare sono:</span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000">temperatura dell'aria;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">umidità relativa dell'aria;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">velocità dell'aria.</span></li>
                                    </ul>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <p style="text-align: justify;"><span style="color:#000000">In condizioni standard, i valori ottimali delle grandezze termoigrometriche sono:</span></p>

                                    <ul style="list-style-type:circle">
                                        <li style="text-align: justify;"><span style="color:#000000">Temperatura: 21-26 °C; &nbsp;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Umidità relativa: 40-60 %; &nbsp;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Velocità dell’aria: 0,10-0,25 m/s.</span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="color:#000000">L'insieme di questi parametri che caratterizzano un ambiente confinato rappresentano il cosiddetto "microclima".</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">E' proprio dalla misurazione di questi parametri che si può stabilire se le condizioni microclimatiche di un determinato ambiente, rientrano nella zona di benessere termico o possono rappresentare uno stress termico o costituiscono un disagio più o meno elevato per l'organismo umano.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">In virtù di ciò la <strong><em>DAFOR S.r.l.</em></strong>&nbsp; svolge, con l’ausilio dei suoi tecnici, <strong>rilievi micro-climatici</strong> mediante strumentazione portatile per il monitoraggio di: temperatura (termometri a infrarossi, data logger, indicatori di temperatura, strisce termometriche,ecc…); umidità, velocità dell’aria, strumenti multifunzione, ecc.</span></p>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

