<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-ambiente">Ambiente</a></li>

            </ul>
            <h2>Consulenze e Analisi</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                    <p style="text-align:justify"><span style="color:#000000">Le condizioni dell'ambiente e la sostenibilità dello sviluppo sono elementi che un'azienda moderna deve necessariamente tenere in considerazione, per poter crescere in un mercato altamente competitivo, anche per effetto della stringente normativa che impone alle aziende la ricerca di soluzioni ambientali strutturate, efficienti ed ispirate a criteri di economicità.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La normativa ambientale </span><a><span style="color:#000000"><strong>(<em>D.Lgs. 152/06</em></strong></span></a><span style="color:#000000">) impegna le aziende in modo crescente sul fronte dell'adeguamento delle tecnologie, sulle corrette modalità di gestione dei processi di produzione, con una costante attenzione all'ambiente.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Avvalendosi di esperti consulenti e qualificati Laboratori di analisi chimico ambientali, la <strong><em>DAFOR S.r.l.</em></strong> è in grado di eseguire consulenze e analisi su:</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ol style="list-style-type:lower-roman">
                                        <li style="text-align:justify"><span style="color:#000000">Acque</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Aria ed Emissioni</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Rifiuti e Materiali</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Terreni e Caratterizzazioni</span></li>
                                    </ol>

                                    <p style="margin-left:18pt; text-align:justify">&nbsp;</p>

                                    <h2 style="text-align:justify"><span style="color:#000000">ACQUE</span></h2>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Caratterizzazione chimica, fisica e microbiologica di acque di scarico ai sensi del D.Lgs. 152/06;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Caratterizzazione chimica, fisica e microbiologica di acque destinate al consumo umano;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Studi e ricerche per l'individuazione di tecnologie impiantistiche idonee all'abbattimento degli inquinanti nelle acque di scarico;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Assistenza tecnica per l'avvio e la gestione di impianti di depurazione di acque reflue civili ed industriali;</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <h2 style="text-align:justify"><span style="color:#000000">ARIA ED EMISSIONI</span></h2>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Caratterizzazione chimica e fisica delle emissioni in aria degli stabilimenti per ottemperare al D.Lgs. 152/06;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Valutazione dell'efficienza degli impianti di abbattimento;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Monitoraggio sulla qualità dell'aria negli agglomerati urbani in relazione ai limiti di attenzione e di allarme previsti da D.M. del 15/04/94;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Monitoraggio sulla qualità dell'aria esterna alle discariche di RU;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Studio sulla Valutazione dell'Impatto Ambientale (VIA).</span></li>
                                    </ul>

                                    <h2 style="text-align:justify">&nbsp;</h2>

                                    <h2 style="text-align:justify"><span style="color:#000000">RIFIUTI E MATERIALI</span></h2>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Classificazione di rifiuti industriali e materiali al riutilizzo ai fini dello smaltimento con assistenza in merito agli adempimenti di raccolta, trasporto, recupero e smaltimento dei rifiuti (D.Lgs. 152/06);</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">In particolare si svolgerà:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Identificazione dei rifiuti prodotti con relativo codice CER di appartenenza (pericoloso- non pericoloso)</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Assistenza per un corretto deposito temporaneo, stoccaggio e conferimento a ditta autorizzata per il trasporto e lo smaltimento dei rifiuti prodotti</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Assistenza in caso di contenzioso per contestazioni ricevute dalle Autorità competenti al controllo.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Controllo dei registri carico/scarico dei rifiuti, controllo sulla corretta compilazione dei formulari d'identificazione del rifiuto.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Formazione dedicata agli addetti gestione dei rifiuti in azienda mediante moduli formativi per una corretta istruzione teorico-pratica degli addetti alla manipolazione dei rifiuti prodotti in azienda. Indicazioni specifiche per settore in correlazione agli adempimenti amministrativi obbligatori: tenuta registro carico/scarico, compilazione formulario identificazione rifiuto e dichiarazione ambientale annua (MUD).</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Dichiarazione ambientale per produttori, trasportatori dei rifiuti (Legge 70/94).</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Consulenza e compilazione della dichiarazione annuale (MUD) per ogni tipologia di attività e per ogni specifica figura obbligata: produttore, trasportatore, recuperatore e smaltitore. Presentazione presso la Camera di Commercio competente per territorio. Assistenza in caso di contenzioso con le Autorità competenti al controllo.</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <h2 style="text-align:justify"><span style="color:#000000">TERRENI CARATTERIZZAZIONI</span></h2>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Caratterizzazione chimico-fisica ai fini agronomici.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Piani di concimazione.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Caratterizzazione chimica e fisica di suolo e sottosuolo in aree industriali dismesse e discariche di RU.</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

