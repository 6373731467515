<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-ambiente">Ambiente</a></li>

            </ul>
            <h2>Rilievi Fonometrici Ambiental</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                    <p style="text-align:justify"><span style="color:#000000"><strong>L'inquinamento acustico</strong> è uno dei problemi più diffusi in tutto il mondo ed in continuo aumento.<br>
                                    La <strong><em>DAFOR S.r.l. </em></strong>mette a disposizione del cliente l’esperienza e le competenze nel campo acustico ambientale mediante esperti nel settore e con l’ausilio di Fonometri Integratori&nbsp; ed analizzatori di spettro Classe 1.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">Qui di seguito sono riportati, in maniera sintetica, i servizi erogati nel campo dei "Rilievi fonometrici Ambientali".&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Rilievi e Valutazioni di Impatto Acustico <em>(Legge 447/95);</em></strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Requisiti acustici nei luoghi d'intrattenimento danzante <em>(DPCM 215 - 16/04/99);</em></strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Rilievi fonometrici in ambiente abitativo;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Collaudi acustici di impianti industriali </strong><em>(a norme UNI o a specifica del cliente);</em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Misure fonometriche e Valutazione dei livelli di immissione in ambiente esterno sia per abitazioni che per attività commerciali, artigianali e industriali;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Valutazione dei requisiti acustici passivi degli edifici, certificazioni e collaudi in opera <em>(DPCM 05.12.1997);</em></strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Rilievi e Valutazioni Previsionali di Clima Acustico <em>(Legge 447/95, art.8 comma 3);</em></strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Rilievi e Valutazioni di Impatto Acustico per cantieri temporanei e mobili e per infrastrutture, industrie, insediamenti artigianali, commerciali e civili;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Progettazione Acustica ed Acustica Architettonica</strong> <strong>di ambienti speciali (mense, palestre, auditorium,locali pubblici, ecc.);</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Qualificazione acustica di apparecchiature secondo la Direttiva Macchine (CE/96);</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Redazione di Piani di Zonizzazione Acustica a livello Comunale e sovracomunale;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Dimensionamento di impianti d'insonorizzazione;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Perizie di parte in cause civili.</strong></span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

