<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>Sistemi di autocontrollo H.A.C.C.P.</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image d-none">
        <img src="assets/img/servizi/servizi-alimenti-haccp.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">


                                    <div class="">
                      <div class="">

                    <article>

                    <div class="testo" id="testo">
                      <p style="text-align:justify"><span style="color:#000000"><span style="font-size:20px"><strong>Manuale H.A.C.C.P.</strong> e formazione del personale</span></span>&nbsp;&nbsp;</p>

                    <p style="text-align:justify"><span style="color:#000000"><strong>Realizzazione di sistemi di autocontrollo H.A.C.C.P. (Hazard Analysys Critical Control Point, Analisi di rischio e punti critici di controllo) </strong></span></p>

                    <p style="text-align:justify"><span style="color:#000000">Il 9 novembre 2007 è stato pubblicato sul supplemento ordinario n.228 della Gazzetta Ufficiale n. 261 il </span><a><span style="color:#000000"><strong>D.Lgs. 193/07</strong></span></a><span style="color:#000000">, in attuazione della<a> </a></span><a><span style="color:#000000"><strong>Direttiva CE 41/2004</strong></span></a><span style="color:#000000"> ( <em>Direttiva Killer </em>).</span></p>

                    <p style="text-align:justify"><span style="color:#000000">Infatti il nuovo decreto legislativo, sopra citato, abroga definitivamente il </span><a><span style="color:#000000"><strong>D.Lgs. 155/97</strong></span></a><span style="color:#000000">, nato inizialmente dal recepimento delle direttive 93/43/CEE e 96/3/CEE e dal 1° Gennaio 2006 sostituite dal <strong>Regolamento CE 852/2004 </strong>e successivi ( <em>Pacchetto Igiene </em>).</span></p>

                    <p style="text-align:justify"><span style="color:#000000">Per quanto riguarda l'implementazione del <strong>Sistema di Autocontrollo </strong>basato sul metodo <strong>H.A.C.C.P. </strong>( <em>Hazard Analysis Critical Control Point = Analisi dei Pericoli e Punti Critici di Controllo </em>), obbligatorio dal 1998 dal D.Lgs. 155/97, attualmente è prescritto dall' </span><a><span style="color:#000000"><strong>art. 4 e 5 del Reg. CE 852/04. </strong></span></a></p>

                    <p style="text-align:justify"><span style="color:#000000">In particolare il </span><strong><a><span style="color:#000000">Reg. CE 852/2004</span></a></strong><span style="color:#000000"><strong> </strong>prescrive ad ogni azienda una valutazione documentata dei rischi igienico-sanitari presenti al suo interno in ogni fase della lavorazione dell'alimento secondo il metodo H.A.C.C.P. e la conseguente attuazione di un sistema di prevenzione del rischio al fine di salvaguardare la salubrità dell'alimento e tutelare quindi la salute del consumatore.</span></p>

                    <p style="text-align:justify"><span style="color:#000000">Secondo l'art. 6, comma 6 del </span><a><span style="color:#000000"><strong>D.Lgs. 193/07</strong></span></a><span style="color:#000000">, salvo che il fatto costituisca reato, l'operatore del settore alimentare che omette di predisporre procedure di autocontrollo basate sui principi del sistema HACCP sarà punito con la sanzione amministrativa pecuniaria da € <em><strong>1.000 </strong></em>a € <em><strong>6.000 </strong></em>.</span></p>

                    <p style="text-align:justify"><span style="color:#000000">La <strong>DAFORS.r.l. </strong>fornisce consulenza ed assistenza per la progettazione e lo sviluppo di Piani di autocontrollo destinati ad operatori del settore alimentare in ogni fase della produzione, trasformazione e distribuzione di:</span></p>

                    <ul>
                        <li style="text-align:justify"><span style="color:#000000">Prodotti alimentari ( </span><strong><a><span style="color:#000000">Reg. CE 852/2004</span></a><span style="color:#000000"> </span></strong><span style="color:#000000">)</span></li>
                        <li style="text-align:justify"><span style="color:#000000">Alimenti di origine animale (<a> </a></span><strong><a><span style="color:#000000">Reg. CE 853/2004</span></a></strong><span style="color:#000000"><strong> </strong>)</span></li>
                        <li style="text-align:justify"><span style="color:#000000">Mangimi ( </span><strong><a><span style="color:#000000">Reg. CE 183/2005</span></a></strong><span style="color:#000000"><strong> </strong>)</span></li>
                    </ul>

                    <p style="text-align:justify"><span style="color:#000000">La <strong>DAFORS.r.l. </strong>è inoltre in grado di assistere gli operatori del settore alimentare in materia di:</span></p>

                    <ul>
                        <li style="text-align:justify"><span style="color:#000000">Corretta applicazione e gestione dei piani di autocontrollo;</span></li>
                        <li style="text-align:justify"><span style="color:#000000">Pianificazione ed effettuazione di verifiche ispettive interne;</span></li>
                        <li style="text-align:justify"><span style="color:#000000">Riesame e alla revisione del piano di autocontrollo;</span></li>
                        <li style="text-align:justify"><span style="color:#000000">Gestione dei rapporti con gli Organi di Controllo.</span></li>
                    </ul>

                    <p style="text-align:justify"><span style="color:#000000"><strong>Le imprese interessate:</strong><br>
                    Sono quelle del settore alimentare: ogni soggetto pubblico o privato con o senza fini di lucro che esercita una o più delle seguenti attività: settore primario (<em>prodotti della terra, dell'allevamento, della caccia e della pesca</em>), mangimi, preparazione, trasformazione, fabbricazione, confezionamento, deposito, trasporto, distribuzione, manipolazione, vendita e fornitura compresa la somministrazione dei prodotti alimentari.</span></p>

                    <p style="text-align:justify">&nbsp;</p>

                    <p style="text-align:justify"><span style="color:#000000"><strong>Punti chiave:</strong></span></p>

                    <p style="text-align:justify"><span style="color:#000000">Il metodo HACCP si basa su un <strong>approccio</strong> alla sicurezza alimentare di tipo <strong>preventivo</strong>, <strong>sistematico</strong> e <strong>documentato</strong>.</span></p>

                    <p style="text-align:justify"><span style="color:#000000">Un gruppo interdisciplinare descrive il <strong>prodotto</strong> e le sue <strong>fasi di lavorazione</strong> e ne identifica la <strong>destinazione d'uso</strong>.</span></p>

                    <p style="text-align:justify"><span style="color:#000000"><strong>Il fulcro della metodologia HACCP è</strong> :</span></p>

                    <ul>
                        <li style="text-align:justify"><span style="color:#000000">l'individuazione di <strong>punti di controllo critici</strong> (CCP);</span></li>
                        <li style="text-align:justify"><span style="color:#000000">il <strong>monitoraggio </strong>e la <strong>gestione</strong> dei <strong>CCP</strong> secondo quanto riportato nel Manuale HACCP;</span></li>
                        <li style="text-align:justify"><span style="color:#000000">l'individuazione di opportuni <strong>indicatori</strong> (temperatura, pH, tempo,&amp; );</span></li>
                        <li style="text-align:justify"><span style="color:#000000">l'individuazione di <strong>limiti critici</strong> di intervento;</span></li>
                        <li style="text-align:justify"><span style="color:#000000">l'individuazione di <strong>livelli di sicurezza</strong>;</span></li>
                        <li style="text-align:justify"><span style="color:#000000">la predisposizione del <strong>piano di interventi</strong> <strong>correttivi</strong>.</span></li>
                    </ul>

                    <p style="text-align:justify"><span style="color:#000000"><strong>Iter di certificazione:</strong></span></p>

                    <ul>
                        <li style="text-align:justify"><span style="color:#000000">sopralluogo aziendale per verificare i processi alimentari, i potenziali rischi e individuare quindi i punti critici</span></li>
                        <li style="text-align:justify"><span style="color:#000000">supporto all’individuazione di adeguate misure correttive e individuazione dei punti critici</span></li>
                        <li style="text-align:justify"><span style="color:#000000">monitoraggio dei punti critici attraverso la predisposizione di schede di registrazione apposite</span></li>
                        <li style="text-align:justify"><span style="color:#000000">stesura del manuale di autocontrollo</span></li>
                        <li style="text-align:justify"><span style="color:#000000">formazione del personale</span></li>
                        <li style="text-align:justify"><span style="color:#000000">verifiche ispettive interne</span></li>
                    </ul>

                    <p style="text-align:justify"><span style="color:#000000"><strong>Vantaggi</strong></span></p>

                    <ul>
                        <li style="text-align:justify"><span style="color:#000000">Rendere visibile <strong>l'impegno</strong> dell'Organizzazione <strong>nella gestione della prevenzione dei pericoli igienico-sanitari</strong>, impegno che va oltre il semplice rispetto della legislazione vigente.</span></li>
                        <li style="text-align:justify"><span style="color:#000000">Dotarsi di un'<strong>efficace strumento</strong> per tenere sotto <strong>controllo il processo produttivo</strong> e, attraverso l'evidenza dei punti di controllo critici, pianificarlo al meglio.</span></li>
                        <li style="text-align:justify"><span style="color:#000000">Utilizzare l<strong>'approccio preventivo alla gestione dei pericoli</strong> per il controllo dei processi produttivi all'interno di un sistema di gestione più complesso, quale ad esempio il Sistema Qualità.</span></li>
                    </ul>

                    <p>&nbsp;</p>


                    </div>
                    </article>







                      </div>
                    </div>


                         </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

