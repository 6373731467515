<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
            <h2>Privacy Policy</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="privacy-policy-content">
                    <div>
                      <p><span>Il presente documento di privacy policy descrive le finalità e le modalità del trattamento dei dati personali di clienti o utenti del sito web: <strong>dafor.it</strong> (di seguito <strong>“Sito”</strong>) gestito da <strong>DaFor S.r.l.</strong> (il documento non include i trattamenti svolti su altri siti web eventualmente consultabili dall’utente tramite link presenti sul Sito).</span></p>
                      <p><span>Si tratta di un documento di natura generale che informa circa i criteri del corretto trattamento dei dati personali svolto sul o tramite il Sito, criteri che l’utente potrà trovare illustrati in dettaglio all’interno delle Informative pubblicate – in base all’art. 13 del d.lgs. 30 Giugno 2003, n. 196 (Codice della privacy) – nelle varie sezioni del Sito volte a specificare le modalità e le finalità dei trattamenti connessi alla fornitura di specifici servizi e/o informazioni di varia natura.</span></p>
                      <p><span>Il presente documento riprende volutamente i contenuti della Privacy Policy applicata dall’Autorità Garante per la protezione dei dati personali con riferimento al trattamento di dati personali degli utenti che si collegano al sito web ufficiale www.garanteprivacy.it . E’ difatti intenzione di <strong>DaFor S.r.l. </strong>conformare le proprie politiche di tutela della privacy a quanto previsto – oltre che dal Codice della Privacy – anche alle cautele ed alle procedure che il Garante ha specificato proprio nel documento sulla Privacy Policy pubblicato sul sito web ufficiale dell’Autorità. Tra queste, le indicazioni contenute nella Raccomandazione n. 2/2001 che le autorità europee per la protezione dei dati personali, riunite nel Gruppo istituito dall’art. 29 della direttiva n. 95/46/CE, hanno adottato il 17 Maggio 2001 per individuare alcuni requisiti minimi per la raccolta di dati personali on-line, e, in particolare, le modalità, i tempi e la natura delle informazioni che i titolari del trattamento devono fornire agli utenti quando questi si collegano a pagine web, indipendentemente dagli scopi del collegamento.</span></p>
                      <p><span><strong>Titolare del trattamento dei dati personali (art. 28 del Codice della Privacy)</strong></span></p>
                      <p><span>A seguito della consultazione di questo Sito possono essere trattati dati relativi a persone identificate o identificabili.</span></p>
                      <p><span>Il Titolare del loro trattamento è&nbsp;<strong>DaFor S.r.l.</strong>, che ha sede in Battipaglia (Sa) – 84091 – Via E. Montale, 1,&nbsp;e-mail: </span><a href="mailto:info@dafor.it"><span>info@dafor.it</span></a><span>.</span></p>
                      <p><span><strong>Luogo del trattamento dei dati personali</strong></span></p>
                      <p><span>I trattamenti connessi ai servizi web di questo Sito hanno luogo presso la predetta sede di <strong>DaFor S.r.l.</strong> e/o presso la sede dei fornitori di servizi di comunicazione elettronica dell’azienda, e sono curati solo da personale tecnico incaricato del trattamento, oppure da eventuali incaricati di occasionali operazioni di manutenzione.</span></p>
                      <p><span>La comunicazione ed eventuale diffusione dei dati personali avvengono solo in base a quanto previsto dal Codice della privacy: con il consenso degli interessati o nei casi di legge in base alla disciplina contenuta negli artt. 24 e 25 del Codice.</span></p>
                      <p><span><strong>CATEGORIE E TIPOLOGIA DI DATI PERSONALI OGGETTO DI TRATTAMENTO</strong></span></p>
                      <p><span><strong>Dati di navigazione</strong></span></p>
                      <p><span>I sistemi informatici e le procedure software preposte al funzionamento del Sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.</span></p>
                      <p><span>Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.</span></p>
                      <p><span>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.</span></p>
                      <p><span>Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del Sito: salva questa eventualità, allo stato i dati sui contatti web non vengono conservati in maniera permanente, a meno di eventuali richieste dell’utente (es: accesso alle proprie pagine del Sito che riepilogano i servizi fruiti, le informazioni richieste, etc).</span></p>
                      <p><span><strong>Dati forniti volontariamente dall’utente</strong></span></p>
                      <p><span>L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati sul Sito comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva.</span></p>
                      <p><span>Specifiche informative di sintesi verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.</span></p>
                      <p><span>Utilizzo dei cookies o di sistemi di tracciatura/monitoraggio permanente degli utenti.</span></p>
                      <p><span>Sul Sito viene fatto uso di cookies per la trasmissione di informazioni di carattere personale. Un cookie è un file di testo che risiede sul disco fisso dell’utente, ove è stato inviato attraverso la pagina web del nostro server. L’utente può in ogni momento scegliere se attivare o disattivare i cookies modificando le impostazione del proprio browser. Di solito, l’utilizzo di cookies rende l’utilizzo del sito web più veloce e facile. Sul Sito non vengono comunque utilizzati sistemi per il tracciamento permanente degli utenti.</span></p>
                      <p><span>L’uso di c.d. cookies di sessione (che non vengono memorizzati in modo persistente sul computer dell’utente e svaniscono con la chiusura del browser) è strettamente limitato alla trasmissione di identificativi di sessione (costituiti da numeri casuali generati dal server) necessari per consentire l’esplorazione sicura ed efficiente del Sito.</span></p>
                      <p><span>I c.d. cookies di sessione utilizzati sul Sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l’acquisizione di dati personali identificativi dell’utente.</span></p>
                      <p><span><strong>Dati sensibili</strong></span></p>
                      <p><span><strong>DaFor S.r.l. </strong>non richiede né raccoglie tramite il Sito dati personali sensibili (come ad esempio le informazioni idonee a rivelare l’origine razziale o etnica, le convinzioni politiche, filosofiche o religiose, le abitudini sessuali o i dati sanitari). In caso contrario, il preventivo consenso scritto o equivalente sarà richiesto all’utente.</span></p>
                      <p><span>Facoltativita’ del conferimento dei dati.</span></p>
                      <p><span>A parte quanto specificato per i dati di navigazione, l’utente è sempre libero di fornire i dati personali per le finalità in base alle quali si connette al Sito (es. richiesta di informazioni tramite compilazione di form, registrazione a servizi particolari, acquisto di beni o di servizi, etc). Tuttavia, il mancato conferimento dei dati può in qualche caso comportare l’impossibilità di ottenere quanto richiesto.</span></p>
                      <p><span><strong>Finalità del trattamento</strong></span></p>
                      <p><span><strong>DaFor S.r.l. </strong>&nbsp;raccoglie e tratta dati personali dei clienti e degli utenti del Sito per porre in essere le proprie attività e affari, per rendere disponibili informazioni, prodotti e servizi che possono essere di interesse e per finalità di rilevazioni statistiche sull’utilizzo del sito web. Le informazioni fornite dall’utente saranno utilizzate per contattarlo quando necessario, ad esempio per notificargli modifiche nella funzionalità del sito web o comunicargli offerte di servizi (previo consenso) che possono essere di interesse.</span></p>
                      <p><span>Se l’utente è un consumatore, il trattamento dei dati personali per finalità commerciali o di marketing sarà basato sulla acquisizione del preventivo e specifico consenso. Se l’utente è un professionista e non desidera ricevere alcun messaggio pubblicitario da <strong>DaFor S.r.l.</strong>, in tal caso può contattarci all’indirizzo: <strong>DaFor S.r.l.</strong>, Via E. Montale, 1 - &nbsp;84091 Battipaglia (Sa), e-mail: </span><a href="mailto:info@dafor.it"><span>info@dafor.it</span></a><span>. In caso di qualsiasi domanda o richiesta circa la presente Informativa Privacy, i nostri trattamenti di dati personali o la navigazione dell’utente in questo sito, si può contattare <strong>DaFor S.r.l.</strong>.</span></p>
                      <p><span><strong>Modalità del trattamento</strong></span></p>
                      <p><span>I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti.</span></p>
                      <p><span>Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.</span></p>
                      <p><span><strong>Diritti degli interessati</strong></span></p>
                      <p><span>I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di ottenere la conferma dell’esistenza o meno dei medesimi dati e di conoscerne il contenuto e l’origine, verificarne l’esattezza o chiederne l’integrazione o l’aggiornamento, oppure la rettificazione (art. 7 del d.lgs. n. 196/2003).</span></p>
                      <p><span>Ai sensi del medesimo articolo si ha il diritto di chiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento.</span></p>
                      <p><span>Le richieste vanno rivolte al Titolare, senza formalità, inviando una comunicazione all’indirizzo e-mail: </span><a href="mailto:info@dafor.it"><span>info@dafor.it</span></a><span>.</span></p></div>
                </div>
            </div>

        </div>
    </div>
</div>
