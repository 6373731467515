import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';

import { ViewChild } from '@angular/core';
import { TabComponent, TabsetComponent } from 'ngx-tabset';

@Component({
  selector: 'app-prodotti-sicurezza-lavoro',
  templateUrl: './sicurezza-lavoro.html',
  styleUrls: ['./sicurezza-lavoro.scss']
})
export class ProdottiSicrezzaLavoro implements OnInit {

    public urlImage = environment.urlImageCorso;
    public urlImageHD = environment.urlImageCorsoHD;
    public urlVideoCorso = environment.urlImageVideoCorso;
    public videoPreview = environment.urlImageVideoCorso;

    public briciolaTag: string;
    public cntClick: number = 1;

    constructor(
        private router: Router,
        private actRoute: ActivatedRoute) { }

   ngOnInit() {
   }

}
