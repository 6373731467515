<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-ambiente">Ambiente</a></li>

            </ul>
            <h2>SERVIZI NEL SETTORE AMBIENTE</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/servizi/servizi-ambiente.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="col-md-12">

                                    <article>

                                    <div class="testo" id="testo">

                                    <p style="text-align:justify"><span style="color:#000000">Le Leggi in vigore nel settore della tutela dell’ambiente&nbsp;richiedono sempre più alle imprese adempimenti, certificazioni e iter burocratici di tipo ecologico-ambientale piuttosto complessi. Questa legislazione, infatti, è caratterizzata da una notevole variabilità a causa del susseguirsi di interventi correttivi e al recepimento di nuove direttive europee,.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La Divisione </span><span style="color:#B22222"><strong>PUNTO QUALITA'</strong> </span><span style="color:#000000">di </span><span style="color:#000080"><em><strong>DAFOR S.r.l.</strong></em> </span><span style="color:#000000">ha creato una serie di servizi per l’ambiente specifici per assistere i propri clienti in questa materia così complessa.</span></p>

                                    <p style="text-align:justify"><span style="color:#000080"><em><strong>DAFOR S.r.l.</strong> </em></span><span style="color:#000000">fornisce i propri servizi in materia di <strong>Tutela Ambientale</strong>&nbsp;a tutte le aziende sul territorio della Campania, delle province di Salerno, Napoli, Avellino, Caserta e Benevento e in tutto il Centro-Sud Italia.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">I Servizi <strong>di consulenza ambientale</strong>&nbsp; per i quali </span><span style="color:#B22222"><strong>PUNTO QUALITA'</strong></span><span style="color:#000000"> mette a disposizione la propria esperienza e competenza per il &nbsp;rispetto delle norme esistenti sono i seguenti:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-ambiente-sanificazione"><span style="color:rgb(0, 0, 205)"><strong><u>Sanificazione Ambientale</u></strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-ambiente-consulenza"><span style="color:rgb(0, 0, 205)"><strong><u>Consulenze e Analisi</u></strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-ambiente-rilievi"><span style="color:rgb(0, 0, 205)"><strong><u>Rilievi Fonometrici Ambientali</u></strong></span></a></span></li>
                                        <li style="text-align:justify"><a href="servizi-ambiente-radon"><span style="font-size:18px"><span style="color:rgb(0, 0, 205)"><strong><u>Misurazione RADON</u></strong></span></span></a></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-qualita-iso14001"><span style="color:rgb(0, 0, 205)"><strong><u>ISO 14001</u></strong></span></a></span></li>
                                    </ul>

                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

