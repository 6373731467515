<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a href="prodotti">Prodotti</a></li>
                <li><a href="prodotti-ambientale">Igiene Ambientale</a></li>
            </ul>
            <h2>Prodotti per la Disinfestazione</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="c">

                                    <article>
                                    <div class="testo" id="testo">
                                      <h1 style="text-align:justify"><span style="color:#000000"><strong><span style="font-size:18px">PRODOTTI PER LA DISINFESTAZIONE</span></strong>&nbsp; </span></h1>

                                    <p style="text-align:justify"><span style="color:#000000">Per disinfestazione generalmente si intendono tutti quei procedimenti atti ad eliminare gli agenti infestanti quali mosche, scarafaggi, roditori e parassiti in generale. Gli animali infestanti costituiscono una vera e propria minaccia per la sicurezza ed igiene degli alimenti, in quanto veicoli di una potenziale contaminazione di natura biologica. Poiché l’infestazione può avvenire più facilmente dove vi sono luoghi adatti alla riproduzione e fonti di nutrimento, devono essere utilizzate le pratiche generali di igiene che consentono di evitare la creazione di un ambiente favorevole alla proliferazione degli infestanti. Tra gli infestanti più dannosi ritroviamo:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">roditori;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">insetti striscianti;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">insetti volanti.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">In particolare il controllo e l’eliminazione dei roditori viene propriamente detto <strong>DERATTIZZAZIONE</strong>.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La <em><strong>DAFOR</strong></em><strong><em> S.r.l.</em></strong> fornisce ai propri clienti e/o a chi lo richiede i mezzi per effettuare il controllo infestanti.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Infatti per la derattizzazione fornisce:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Contenitore di sicurezza per esche rodenticide</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Esche topicide per varie esigenze (tutte corredate da schede tecniche e tossicologiche)</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">Per la lotta contro gli insetti striscianti:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Trappola biologica per la cattura degli scarafaggi, formiche, ecc. mediante pastiglia attraente.</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">Infine per la lotta agli insetti volatili:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Trappola biologica per la cattura degli insetti volanti con attrattivo specifico;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Trappola biologica per il monitoraggio e la cattura dei lepidotteri adulti infestanti le derrate munita di feromoni specifici;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Trappola elettro-luminosa con cartone collante.</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>


                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

