<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>Prevenzione Legionellosi</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                    <p style="text-align:justify"><span style="color:#000000">Ogni struttura turistico-ricettiva dovrebbe effettuare, almeno ogni due anni, un’analisi del rischio Legionellosi secondo quanto suggerito dalla </span><u><strong><a><span style="color:#0000FF">CONFERENZA PERMANENTE PER I RAPPORTI TRA LO STATO, LE REGIONI E LE PROVINCE AUTONOME DI TRENTO E BOLZANO del 13 gennaio 2005</span></a></strong></u><span style="color:#000000"> e dai numerosi atti e documenti di indirizzo elaborati dalle singole Regioni in materia di prevenzione e controllo di questa grave patologia.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Infatti tale Provvedimento suggerisce, a ogni struttura Turistico-Ricettiva e Termale, a prescindere dalle loro dimensioni, di effettuare u<strong>n’Analisi del Rischio Legionellosi</strong> <strong>redigendo un Manuale di Autocontrollo della Legionellosi</strong>, atto a valutare e prevenire i rischi derivanti alla salute del cliente-ospite e dei lavoratori di dette strutture, dovuti ad una cattiva gestione e manutenzione degli impianti da parte dei gestori.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">E’ opportuno informare che la Legionellosi è una grave forma di polmonite causata da batteri appartenenti al genere Legionella, un microrganismo ampiamente diffuso in natura, dove si trova principalmente associato alla presenza di acqua. Concentrazioni elevate possono essere rilevate in impianti idrici <em>(sanitari, riscaldamento, irrigazione, ecc.)</em>, in impianti di climatizzazione dell'aria, in vasche per gli idromassaggi, in piscine natatorie e termali, durante l’aerosol terapia, ecc. La Legionellosi viene generalmente contratta per via respiratoria, mediante inalazione di aerosol in cui è contenuto il batterio.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">La nostra società, nel campo “<strong>Prevenzione Legionellosi”</strong>, opera in questo modo:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Effettuiamo un <strong>Check-up degli impianti</strong> per ottenere informazioni sulle caratteristiche degli impianti idrici e di trattamento dell’aria per individuarne i punti critici e successivamente i&nbsp; rischi in cui potrebbero incorrere gli utenti e il personale al fine di garantire un servizio personalizzato.</span></li>
                                    </ul>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Successivamente viene consegnato il <strong>Documento di Prevenzione del Rischio Legionellosi,</strong> redatto sulla base dei dati rilevati nel check-up aziendale, contenente l'individuazione dei pericoli igienico-sanitari. Inoltre vengono consegnati i relativi <strong>Registri </strong>concernenti le annotazioni degli interventi di pulizia/manutenzione/bonifica ordinari e straordinari degli impianti, dei campionamenti effettati ecc.</span></li>
                                    </ul>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Effettuiamo <strong>Formazione,</strong> dei Responsabili dell’Autocontrollo e del personale addetto alla gestione degli impianti, delucidando in tutte le sue parti il Documento di Prevenzione dei Rischi, descrivendo le misure cautelative per evitare i rischi e illustrando come vengono compilati i relativi Registri.</span></li>
                                    </ul>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Eseguiamo le <strong>Periodiche Analisi</strong> microbiologiche sia sulle acque che sulle superfici/filtri potenzialmente a rischio, secondo quanto previsto dall’<em>Allegato 2 dell</em></span><strong><em><a><span style="color:#000000">’Accordo Stato-Regioni 4 Aprile 2000</span></a></em></strong><span style="color:#000000">.</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

