<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>Sorveglianza Sanitaria</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">

                                    <p style="text-align:justify"><span style="color:#000000">La<em> “<strong>Sorveglianza Sanitaria” </strong></em>è l’insieme degli atti medici, finalizzati alla tutela dello stato di salute e sicurezza dei lavoratori, in relazione all’ambiente di lavoro, ai fattori di rischio professionali e alle modalità di svolgimento dell’attività lavorativa<em>, come definito all’art. 2, lettera m del</em></span><em><strong><a><span style="color:#000000"> D.Lgs.81/08</span></a></strong></em><span style="color:#000000"><em>.</em></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Obiettivi della sorveglianza sanitaria&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                    Tutela dello stato di salute e sicurezza dei lavoratori attraverso:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Valutazione della compatibilità tra condizioni di salute e compiti lavorativi;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Individuazione degli stati di ipersuscettibilità individuale ai rischi lavorativi;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Verifica dell’efficacia delle misure di prevenzione dei rischi attuate in azienda.</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">Infatti, secondo il <em>comma 1 dell'art. 41, D. Lgs. 81/08 s.m.i.</em>, la <strong>sorveglianza sanitaria</strong> è effettuata dal medico competente:</span></p>

                                    <ul style="list-style-type:circle">
                                        <li style="text-align:justify"><span style="color:#000000">nei casi previsti dalla normativa vigente e dalle indicazioni fornite dalla Commissione consultiva di cui all'articolo 6;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">qualora il lavoratore ne faccia richiesta e la stessa sia ritenuta dal medico competente correlata ai rischi lavorativi.</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">La<em><strong> DAFOR &nbsp;S.r.l.</strong></em> si avvale di <strong>medici del lavoro</strong> (<strong><em>propri partners</em></strong>) per effettuare le visite <strong>mediche</strong> e gli <strong>accertamenti</strong> previsti dall’art. 41, comma 2, del </span><strong><a target="_blank"><span style="color:#000000">D.Lgs. 81/08 </span></a></strong><span style="color:#000000">per quanto riguarda la sicurezza dei lavoratori.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">In particolare i medici specializzati in medicina del lavoro garantiscono la corretta gestione dei protocolli di sorveglianza sanitaria effettuando:</span></p>

                                    <ul style="list-style-type:circle">
                                        <li style="text-align:justify"><span style="color:#000000">Visite mediche per l’idonietà alla mansione specifica;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Esami ematochimici di laboratorio;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Esami strumentali, quali:</span>
                                        <ul>
                                            <li><span style="color:#000000">Spirometrie;</span></li>
                                            <li><span style="color:#000000">Audiometrie;</span></li>
                                            <li><span style="color:#000000">Elettrocardiogramma;</span></li>
                                            <li><span style="color:#000000">Visiotest;</span></li>
                                            <li><span style="color:#000000">Ergovision;</span></li>
                                        </ul>
                                        </li>
                                    </ul>

                                    <p style="margin-left:54pt; text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">Inoltre i medici del lavoro offrono la possibilità di essere nominati, dal datore di lavoro, come “<strong>Medico Competente</strong>” da quanto previsto dall’art. 39 del D.Lgs. 81/08 per la predisposizione dell'attuazione delle misure per la tutela della salute e dell'integrità psico-fisica dei lavoratori.</span></p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

