<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>Assistenza Tecnico-Legislativa</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">

                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                    <p style="text-align:justify"><span style="color:#000000">Si tratta di un prezioso sostegno al cliente per tutto ciò che riguarda la normativa e la legislazione nel settore&nbsp; Alimentare.&nbsp;&nbsp;&nbsp;<br>
                                    La DAFOR S.r.l. si avvale di consulenti che assistono il cliente nella valutazione ed elaborazione dei dati integrando il rapporto di prova con relazioni tecniche in riferimento alle problematiche tecnologiche e ai vincoli normativi.</span></p>

                                    <p style="text-align:justify"><br>
                                    <span style="color:#000000">Il personale, costantemente aggiornato sulla normativa e sulla legislazione, prepara i clienti alla completa gestione aziendale. In particolare effettua:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Elaborazione ed Assistenza nelle Analisi di Revisione, Relazioni Peritali;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Ricerche bibliografiche specifiche;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Commento dei certificati, tramite confronto tra i dati analitici ei rispettivi limiti legislativi per:&nbsp; <em>acque destinate al consumo umano, rifiuti, suoli ecc. </em></span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Studio delle Etichette secondo il</span><a href="/public/files/REG_%201169-2011.pdf"><span style="color:#000000"> <strong>Reg. UE 1169/2011</strong></span></a><span style="color:#000000"> e in particolare:</span></li>
                                    </ul>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Corretta etichettatura dei prodotti alimentari;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">etichette conformi alla normativa “allergeni” ;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">etichette nutrizionali;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">etichette prodotti biologici;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">etichette per carni bovine e avicole con indicazioni facoltative certificate.</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

