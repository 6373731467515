<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>SERVIZI NEL SETTORE SICUREZZA SUL LAVORO</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/servizi/servizi-lavoro.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="col-md-12">

                                    <article>
                                    <div class="testo" id="testo">
                                      <p style="text-align:justify"><span style="color:#B22222"><strong>PUNTO QUALITA'</strong></span><span style="color:#000000"> di </span><span style="color:#000080"><strong><em>DAFOR S.r.l.</em></strong></span><span style="color:#000000"><strong> </strong>dà ciò di cui hai bisogno per risolvere ogni problema della Sicurezza sul Lavoro, obbligo D.Lgs 81/2008: &nbsp;analisi dei rischi, progettazione e verifica delle procedure adottate in Azienda, sistemi di gestione della sicurezza</span></p>

                                    <p style="text-align:justify"><span style="color:#000080"><strong>DAFOR S.r.l.</strong></span><span style="color:#000000"><strong> ha creato un team multidisciplinare di ingegneri e architetti per fornire un’assistenza completa alla propria clientela.</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La Divisione </span><span style="color:#B22222"><strong>PUNTO QUALITA</strong></span><span style="color:#000000"><strong>'</strong> fornisce i propri servizi in materia di <strong>Servizi sulla Sicurezza sul Lavoro</strong>&nbsp;a tutte le aziende sul territorio della Campania, delle province di Salerno, Napoli, Avellino, Caserta e Benevento e in tutto il Centro-Sud Italia.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Di seguito i servizi proposti:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-consulenza"><span><strong>Consulenza Sicurezza Aziendale</strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-sorveglianza"><span><strong>Sorveglianza Sanitaria</strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-fonometrici"><span><strong>Rilievi Fonometrici nei Luoghi di Lavoro</strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-vibrazionali"><span><strong>Rilievi Vibrazionali negli Ambienti di Lavoro</strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-micro"><span><strong>Rilievi Micro-Climatici Ambienti di Lavoro</strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-incendi-lavoro"><span><strong>Prevenzione Incendi negli Ambienti di Lavoro</strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-igiene-piscina"><span><strong>Igiene e Sicurezza delle Piscine</strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-prevenzione"><span><strong>Prevenzione Legionellosi</strong></span></a></span></li>
                                        <li style="text-align:justify"><span style="font-size:18px"><a href="servizi-sicurezza-ohsas"><span><strong>OHSAS ISO 18001</strong></span></a></span></li>
                                    </ul>

                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

