<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>Prevenzione Incendi negli Ambienti di Lavoro</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                    <p style="text-align:justify"><span style="color:#000000">In tutte le attività produttive (fabbriche, uffici, impianti, pubblica amministrazione, cantieri, ecc.) è prevista la presenza di una squadra di emergenza ed evacuazione (D.Lgs. 81/08 art. 43), il numero degli addetti all'emergenza viene indicato dal datore di lavoro in relazione al tipo di rischio valutato e va formato in base ai tipi di rischi di incendio dell'intero luogo di lavoro o di ogni parte di esso: tale livello può essere "basso", "medio" o "elevato" (Allegato I D.M. 10/03/98).</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La <strong><em>DAFOR. S.r.l. </em></strong>fornisce, in collaborazione con esperti del settore, tutti servizi utili nel settore antincendio e in particolare:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Predisposizione di progetti e pratiche antincendio</strong></span></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Consiste nella predisposizione di progetti da presentare ai Vigili del Fuoco per il parere preventivo, richieste nei confronti dei Vigili del Fuoco di Certificato di Prevenzione Incendi (CPI), richieste di rinnovo dei CPI e documentazione varia.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Predisposizione del piano di evacuazione ed emergenza</strong></span></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Consiste nell'elaborazione del documento di valutazione dei rischi di incendio e gestione dell'emergenza dell'unità produttiva presa in considerazione.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Predisposizione della relazione del calcolo del carico di incendio</strong></span></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Consiste nell'elaborazione, in base alle norme antincendio vigenti, della relazione con il calcolo del carico di incendio presente nell'unità produttiva presa in considerazione.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Predisposizione del Lay-out di evacuazione</strong></span></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Consiste nell'elaborazione, in base alle norme antincendio vigenti, della pianta di evacuazione da esporre nei luoghi di lavoro con indicate le vie di esodo, le porte di emergenza, le vie di uscita, l'ubicazione dei mezzi antincendio, l'ubicazione della cartellonistica di sicurezza, ecc..</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Informazione ai lavoratori</strong></span></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Predisposizione di appositi moduli di informazione dei lavoratori presenti nell'unità produttiva, il contenuto dell'informazione riguarda la conoscenza dei rischi e dei mezzi antincendio, le modalità di evacuazione, ecc.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><span style="font-size:14px"><strong>Formazione ai lavoratori e degli addetti alle emergenze</strong></span></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Predisposizione di appositi moduli formativi dei lavoratori e degli addetti alle emergenze presenti nell'unità produttiva, il contenuto della formazione riguarda la conoscenza dei rischi e dei mezzi antincendio, le modalità di evacuazione, ecc..</span></p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

