<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a href="prodotti">Prodotti</a></li>
                <li><a href="prodotti-ambientale">Igiene Ambientale</a></li>
            </ul>
            <h2>Prodotti e materiali per la pulizia</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">

                                <div class="col-md-12">

                                    <article>

                                    <div class="testo" id="testo">
                                      <h1 style="text-align:justify"><span style="color:#000000"><strong><span style="font-size:18px">PRODOTTI E MATERIALI PER LA PULIZIA&nbsp;</span></strong></span></h1>

                                    <p style="text-align:justify"><span style="color:#000000">La corretta pulizia (detersione e sanificazione) degli ambienti e delle attrezzature costituisce uno strumento fondamentale per contenere il rischio della contaminazione microbica a carico dei prodotti alimentari.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">In particolare lo scopo della <strong>sanificazione</strong> è distruggere tutti i batteri patogeni eventualmente presenti e ridurre al minimo la contaminazione batterica generica.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Per questa ragione la <strong><em>DAFOR S.r.l.</em></strong> fornisce ai suoi clienti e/o a chi lo richiede prodotti e materiali per la pulizia.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">In particolare fornisce come prodotti per la pulizia:</span></p>

                                    <ol>
                                        <li style="text-align:justify"><span style="color:#000000">Detergenti alcalini;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Detergenti a bassa alcalinità;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Detergenti disincrostanti acidi;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Sanificanti a base di cloro;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Sanificanti a base di sali quaternari d'ammonio.</span></li>
                                    </ol>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>N.B.:</strong> Tutti i prodotti sono dotati di schede tecniche di sicurezza.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Mentre come materiali e sistemi vari si forniscono:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Distributore carta monouso per varie applicazioni;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Dispenser sapone liquido;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Arredi per bagni;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Nebulizzatore elettrico per disinfettare le mani, piedi a calzature;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Idropulitrici per industrie alimentari;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Sistemi di sanificazione ad erogazione di schiuma.</span></li>
                                    </ul>



                                    </div>
                                    </article>







                                      </div>


                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

