import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { Router, ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';

import { ViewChild } from '@angular/core';
import { TabComponent, TabsetComponent } from 'ngx-tabset';

@Component({
  selector: 'app-servizi-alimenti-tracciabilita',
  templateUrl: './servizi-alimenti-tracciabilita.html',
  styleUrls: ['./servizi-alimenti.scss']
})
export class ServiziAlimentiTracciabilita implements OnInit {

    public urlImage = environment.urlImageCorso;
    public urlImageHD = environment.urlImageCorsoHD;
    public urlVideoCorso = environment.urlImageVideoCorso;
    public videoPreview = environment.urlImageVideoCorso;

    public briciolaTag: string;
    public cntClick: number = 1;

    constructor(
        private crudService: CrudService,
        private router: Router,
        private actRoute: ActivatedRoute) { }

   ngOnInit() {
   }

}
