<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>Servizi Settore Alimentare</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/servizi/servizi-alimenti.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <p>
                                    Normative cogenti e Consumatori sempre più attenti e consapevoli dell’importanza di un’alimentazione sicura pongono alle imprese alimentari la necessità di adottare procedure igieniche e controlli adeguati per tutelare la salute.
                                </p>
                                <p>
                                    Garantire la sicurezza alimentare significa gestire e controllare tutto il percorso che va dall’acquisto delle materie prime, fino alla preparazione, trasporto, conservazione, somministrazione e distribuzione/vendita degli alimenti.
                                </p>
                                <p>
                                    Sicurezza e Qualità sono un binomio inscindibile e DAFOR S.r.l. con la propria divisione PUNTO QUALITÀ con un’esperienza maturata in oltre un decennio di consulenza si è posta la mission di trasformare gli obblighi in opportunità.
                                    <strong>DAFOR S.r.l. ha creato un team multidisciplinare di dottori in Scienze e Tecnologie Alimentari per fornire un’assistenza completa alla propria clientela.</strong>
                                </p>
                                <p>
                                    La Divisione PUNTO QUALITA' fornisce i propri servizi in materia di Sicurezza Alimentare a tutte le aziende sul territorio della Campania, delle province di Salerno, Napoli, Avellino, Caserta e Benevento e in tutto il Centro-Sud Italia.
                                </p>
                                <h4 class="fw-bolder mt-5">Di seguito i servizi proposti:</h4>
                                <ul class="mb-5">
                                    <li><a class="fw-bold" href="servizi-alimenti-haccp">Realizzazione di sistemi di autocontrollo H.A.C.C.P;</a></li>
                                    <li><a class="fw-bold" href="servizi-alimenti-bollino-blu">Bollino Blu per la ristorazione;</a></li>
                                    <li><a class="fw-bold" href="servizi-alimenti-analisi">Analisi degli Alimenti;</a></li>
                                    <li><a class="fw-bold" href="servizi-alimenti-tracciabilita">Tracciabilità Filiera Produttiva;</a></li>
                                    <li><a class="fw-bold" href="servizi-alimenti-tecnologie">Tecnologie per la ristorazione;</a></li>
                                    <li><a class="fw-bold" href="servizi-alimenti-rilievi">Rilievi Micro-Climatici;</a></li>
                                    <li><a class="fw-bold" href="servizi-alimenti-iso22000">ISO 22000;</a></li>
                                    <li><a class="fw-bold" href="servizi-alimenti-assistenza">Assistenza Tecnico-Legislativa;</a></li>
                                    <li><a class="fw-bold" href="servizi-alimenti-assistenza-legale">Assistenza Legale;</a></li>
                                </ul>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

