<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-qualita">Qualità</a></li>

            </ul>
            <h2>ISO 14001</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                    <p style="text-align: justify;"><span style="color:#000000">Nelle società moderne c’è sempre meno spazio per le organizzazioni che non dimostrano sensibilità nei confronti dell’ambiente e pertanto, le aziende che vogliono mantenere alta la loro immagine di mercato, devono organizzarsi per mantenere sotto controllo gli <strong>impatti ambientali</strong> (interni ed esterni) originati dalle loro attività.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">La politica di gestione dell’ambiente, inoltre, aiuta a valorizzare l’ambiente di lavoro interno all’organizzazione, favorendo il coinvolgimento della risorsa umana nell’assicurare ritorni significativi sulle performance dell’azienda.</span></p>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <p style="text-align: justify;"><span style="color:#000000">La norma&nbsp;</span><a><span style="color:#000000"><strong>UNI EN ISO 14001</strong></span></a><span style="color:#000000"> fornisce il modello che deve essere realizzato per garantire la <strong>gestione controllata dell’ambiente</strong> e la <strong>Certificazione del Sistema di Gestione Ambientale</strong>, inoltre fornisce la garanzia che l’azienda attua una seria politica ambientale e rispetta i requisiti legislativi in materia di ambiente. Una Certificazione, pertanto, che contribuisce a migliorare l’immagine dell’azienda sul mercato.</span></p>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <p style="text-align: justify;"><span style="color:#000000"><strong>Che cos’è un Sistema di Gestione Ambientale (SGA)</strong></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">L'obiettivo di un sistema di gestione ambientale è definire volontariamente dei traguardi per migliorare continuamente e prevenire gli effetti ambientali della propria azienda.<br>
                                    Individuare gli effetti ambientali e valutare i principali punti di forza e di debolezza della propria impresa, dal punto di vista legislativo, tecnico e organizzativo, sono il primo e fondamentale passo per costruire un sistema efficace ed eff</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">icace ed efficiente.<br>
                                    Questa prima fase di analisi degli aspetti ambientali, degli impatti, delle performances ambientali e delle attività di controllo connesse, comunemente chiamata analisi ambientale iniziale, consiste quindi in un auto-controllo, a cui farà seguito la decisione sulla politica ambientale che dovrà fungere da guida alla pianificazione dei provvedimenti di intervento.</span></p>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <p style="text-align: justify;"><span style="color:#000000"><strong>I vantaggi per l’impresa</strong><br>
                                    E' spontaneo pensare subito che la tutela dell'ambiente non possa che non comportare dei costi per l'impresa, ma in realtà è un modo errato di affrontare la questione quella di considerare la realizzazione di un sistema di gestione ambientale come solo un fattore di costo.<br>
                                    Senza volersi dilungare sui vantaggi di tipo "ambientale", che comunque non vogliono da noi essere trascurati, numerosi possono essere i benefici che possono derivare alle aziende nell'adottare un&nbsp;<strong>SGA</strong>&nbsp;e volerne fare un elenco sarebbe riduttivo se non inappropriato, dal momento che questi sono in stretta dipendenza con la tipologia dell'impresa.<br>
                                    Detto questo, a semplice titolo esemplificativo, possiamo cercare di riassumere quelli che potrebbero essere i possibili benefici per l'impresa conseguenti all'adozione dello standard&nbsp;<strong>ISO 14001:</strong></span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000">Controllo e mantenimento della&nbsp;<strong>conformità legislativa</strong>&nbsp;e&nbsp;<strong>monitoraggio delle prestazioni ambientali</strong>.</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Agevolazioni</strong>&nbsp;nelle procedure di&nbsp;<strong>finanziamento</strong>&nbsp;e semplificazioni burocratiche/amministrative.&nbsp;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Riduzione</strong>&nbsp;degli&nbsp;<strong>sprechi</strong>&nbsp;(consumi idrici, risorse energetiche, ecc.).</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Strumento di&nbsp;<strong>supporto</strong>&nbsp;nelle decisioni di&nbsp;<strong>investimento</strong>&nbsp;o di cambiamento tecnologico.</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Strumento di creazione e mantenimento del&nbsp;<strong>valore aziendale</strong>.</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Strumento di&nbsp;<strong>salvaguardia del patrimonio aziendale</strong>&nbsp;e di trasparenza in operazioni di acquisizioni/fusioni (gestione dei rischi).</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Garanzia di un&nbsp;<strong>approccio sistematico</strong>&nbsp;e preordinato alle emergenze ambientali.</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Migliore rapporto e&nbsp;<strong>comunicazione</strong>&nbsp;con le autorità.</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Miglioramento dell'<strong>immagine aziendale</strong>.</span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="color:#000000">Inoltre la&nbsp;<strong>Certificazione del Sistema di Gestione Ambientale</strong>&nbsp;secondo&nbsp;<strong>UNI EN ISO 14001&nbsp;</strong>rappresenta il primo passo verso la&nbsp;<strong><em>Convalida della Dichiarazione Ambientale secondo il Regolamento CE n° 761/2001</em></strong>&nbsp;(<strong>EMAS</strong>). Secondo questo regolamento, infatti, le organizzazioni che vogliono registrarsi EMAS devono attuare un Sistema di Gestione Ambientale conforme ai requisiti della norma UNI EN ISO 14001, riconosciuta quindi come un importante riferimento per qualificare anche la gestione EMAS.</span></p>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

