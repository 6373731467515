<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>Tecnologie per la ristorazione</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                      <p style="text-align:justify"><span style="color:#000000"><span style="font-size:16px"><strong>Che cosa è</strong></span></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Il nostro staff </strong>è in grado di fornire consulenza tecnica nel settore ristorativo, definendo gli standard qualitativi e i mezzi di controllo, in modo da:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">elaborare la carta dei servizi inerente la ristorazione collettiva (mense pubbliche, aziendali, scolastiche, ospedaliere) nonché menù speciali: preparazioni alimentari alternative per allergie e/o intolleranze alimentari;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">progettare e verificare il processo di produzione pasti nell'ambito della ristorazione collettiva mediante tecniche di controllo e garanzia dei sistemi produttivi;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">stabilire le caratteristiche tecniche e logistiche degli spazi;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">impostare un Lay-out per i flussi di produzione;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">fornire approfondita conoscenza della normativa del settore, della logistica degli approvvigionamenti:gestione delle materie prime; stoccaggio materie prime e tecniche di conservazione delle derrate; trattamento delle eccedenze; trasformazione (modalità di preparazione degli alimenti) e distribuzione dei prodotti finiti;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">effettuare una “rintracciabilità”, ovvero l’obbligo di mettere in atto delle procedure adeguate per individuare l’origine degli alimenti e di ogni prodotto o sostanza utilizzata nei processi produttivi, per agevolare l’individuazione delle cause di un problema, per ritirare ove necessario i prodotti dal mercato qualora sussistano pericoli per la salute pubblica;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">provvedere ai controlli analitici mediante analisi microbiologiche;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">fornire informazioni sui criteri di valutazione della sicurezza igienica;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">effettuare formazione ed addestramento del personale addetto alla manipolazione dei prodotti assicurandosi che sia in buona di salute e che abbia ricevuto un’adeguata formazione sui rischi sanitari;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">sviluppare piani di autocontrollo secondo metodologia HACCP;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">effettuare implementazione di manuali per la certificazione del marchio <strong>“</strong></span><strong><a><span style="color:#000000">Bollino Blu</span></a></strong><span style="color:#000000"><strong>”</strong> che è rivolto a tutte le imprese ristorative.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000"><em><strong>Gli obiettivi fondamentali della certificazione sono:</strong></em></span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">riduzione dei rischi da alimentazione a tutela della salute dei consumatori</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">condivisione con le autorità di controllo di obiettivi di sicurezza alimentare<br>
                                        Verranno inoltre illustrate le opportunità per la valorizzazione delle caratteristiche di sicurezza dei prodotti fornendo le linee guida per un approccio realizzabile e compatibile con le realtà aziendali.</span></li>
                                    </ul>

                                    <p>&nbsp;</p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

