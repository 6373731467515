<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>OHSAS ISO 18001</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="col-md-12">

                                    <article>
                                    <div class="testo" id="testo">
                                      <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:20px"><strong>OHSAS ISO 18001 - Certificazione per la sicurezza nei luoghi di lavoro</strong></span>&nbsp;</span></p>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <p style="text-align: justify;"><span style="color:#000000"><strong>La certificazione OHSAS (Occupational Health and Safety Assessment Series) ISO 18001</strong> consente di tenere sotto controllo i rischi relativi all'attività lavorativa, di perfezionare le prestazioni, <strong>di rendere l'ambiente di lavoro più sicuro e di applicare e rispettare correttamente le leggi.</strong></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">Tale certificazione supporta le organizzazioni nella formulazione di obiettivi e politiche a favore della <strong>sicurezza e della salute dei lavoratori,</strong> tenendo conto della presenza di pericoli e rischi effettivi e potenziali sui posti di lavoro.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">A seguito dell'evoluzione del mondo del lavoro al fine di raggiungere validi standard qualitativi, ridecndo al contempo i rischi per i lavoratori da infortuni e pericoli, la certificazione <strong>OHSAS 180001</strong> è da csiderarsi una grande opportunità per qualsiasi realtà aziendale.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><strong>I VANTAGGI DELLA CERTIFICAZIONE OHSAS 18001 </strong></span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Assicurazione di conformità alla norma del Sistema di Gestione per la Sicurezza e la Salute adottato dall'azienda. </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Potenziamento della cultura aziendale in tema di sicurezza dei luogi di lavoro.</strong>.</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Coinvolgimento dei dipendenti in un progetto motivante che li riguarda direttamente. </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Garanzia di rispetto delle norme di legge in materia e di conformità in caso di verifiche. </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Riduzione dei costi, grazie all'aumento dell'efficienza e alla riduzione degli incidenti. </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Risparmio nei premi assicurativi da versare, perchè si dimostra di fare prevenzione e miglioramento. </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Qualificazione dell'immagine istituzionale e dei rapporti con clienti. </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Riconoscibilità sul piano internazionale</strong>.</span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="color:#000000">Tali vantaggi creano le giuste condizioni per nuovi impulsi competitivi a cui le aziende ambiscono per presentarsi al meglio sia sul mercato nazionale che internazionale.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><strong>La Divisione PUNTO QUALITÀ è in grado, grazie anche al supporto di un partner certificato Accredia</strong>, di supportare le aziende nel <strong>processo di definizione</strong> della certificazione attraverso le seguenti fasi:</span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Raccolta documentale del cliente (Analisi dei Rischi e Manuale Sicurezza del Lavoro) </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>erifica aziendale per la verifica dell'asset organizzativo e audit di certificazione rispetto ai requisiti dello standard di riferimento. </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Emissione del certificato dopo la verifica di conformità dell'Organizzazione secondo le linee guida del Comitato di Certificazione </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Sorveglianza periodica per la verifica sul rispetto del Sistema di Certificazione adottato. </strong></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><strong>Rinnovo triennale del Sistema.</strong></span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

