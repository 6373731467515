<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>
            </ul>
            <h2>Assistenza Legale</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">

                                    <p style="text-align:justify"><span style="color:#000000">La <em><strong>DAFOR &nbsp;S.r.l.</strong></em> fornisce, inoltre, per dare servizi a 360° in ambito alimentare, consulenza ed assistenza legale, in materia di diritto civile e diritto penale, tramite avvocati (<strong><em>nostri partners</em></strong>) specializzati in legislazione alimentare offrendo assistenza giudiziaria presso:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">la Corte di Cassazione;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">le Corti giudiziarie ordinarie ed amministrative;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">le Autorità Sanitarie.</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

