<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-ambiente">Ambiente</a></li>

            </ul>
            <h2>Misurazione RADON</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="col-md-12">

                                    <article>

                                    <div class="testo" id="testo">

                                    <p style="text-align:justify"><span style="color:#000000">Le maggiori concentrazioni di radon si raggiungono negli ambienti chiusi (abitazioni), in quanto tale gas è principalmente presente nel suolo e nei materiali da costruzione. L’aria esterna agisce come diluente poiché la concentrazione di radon all’aperto è generalmente molto bassa.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La nuova direttiva europea in materia di radioprotezione, <strong>2013/59/Euratom</strong>, impone la varie Regioni ad adottare provvedimenti per la misurazione del gas RADON.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Nello specifico la suddetta Legge prescrive la misura di radon, su tutta la Campania, per tutti i luoghi accessibili al pubblico. In particolare per gli <u>interrati, seminterrati e locali a piano terra, aperti al pubblico</u>, con esclusione dei residenziali e dei vani tecnici isolati al servizio di impianti a rete, il livello limite di riferimento per concentrazione di attività di gas radon in ambiente chiuso non può superare <u>300 Bq/mc</u>, misurato <strong>con strumentazione passiva tramite rivelatori a tracce nucleari</strong>. La misura deve essere determinata come valore medio ponderale di concentrazione su un <strong>periodo annuale suddiviso in due semestri</strong> primaverile-estivo e autunnale-invernale.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Entro 18 mesi dal 16/07/2019</strong> (ovvero entro il 16/01/2021) ogni attività suddetta è obbligata ad inviare al Comune e all'ARPAC i risultati delle <strong>misurazioni annuali</strong> della concentrazione del gas Radon all’interno del proprio locale.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong><u>Il termine fissato dalla Legge per l'inizio misurazione è</u></strong> di 90 giorni dalla entrata in vigore, ovvero<strong><u> il</u></strong><u> <strong>16/10/2019.</strong></u></span></p>


                                    <p style="text-align:justify"><span style="color:#000000"><strong>In caso di mancata trasmissione</strong> delle misurazioni entro diciotto mesi dalla data di entrata in vigore della legge regionale, il comune provvede a intimare con ordinanza la trasmissione delle misurazioni svolte, concedendo un termine non superiore a trenta giorni, la cui eventuale e infruttuosa scadenza comporta la <strong><u>sospensione della certificazione di agibilità. Il che significa non poter più svolgere l'attività all’interno del locale poiché ritenuto inagibile.</u></strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Gli <strong>svantaggi</strong> di non effettuare la misura del Radon nei tempi previsti e quindi di verdersi sospesa l'agibilità del locale sono:</span></p>

                                    <ol>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Possibile chiusura</strong> del locale per inagibilità.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Impossibilità di affitto o vendita</strong> del locale, per mancanza di agibilità.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Impossibilità di effettuare future richieste al SUAP per <strong>subentri, volture, variazioni dell'attività</strong>.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Impossibilità di avviare nuove attività nel locale.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">In caso di presenza di assicurazioni su danni o eventi avversi, possibile <strong>non applicabilità di contratti di assicurazione</strong>.</span></li>
                                    </ol>

                                    <p style="text-align:justify"><span style="color:#000000">Sebbene la Legge imponga la misura all'esercente, tali effetti negativi si ripercuotono anche sul proprietario, poichè risulterà impossibilitato a disporre del locale ed a trarne un vantaggio economico. E' importante notare che<strong> la Legge impone una misura di durata annuale, per cui in caso di non esecuzione nei tempi prescritti, il locale risulterà interdetto per almeno 1 anno</strong>, nel frattempo che vengano effettuate le misurazioni.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#FF0000"><span style="font-size:20px"><strong>COME OPERIAMO</strong></span></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La <em><strong>DAFOR S.r.l.</strong></em> con il proprio marchio <strong>PUNTO QUALITA'</strong>, fornisce la consulenza e l’assistenza necessari a assolvere tale obblighi.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">In particolare, attraverso i propri tecnici, esegue la misurazione del gas Radon mediante dosimetri passivi a tracce nucleari in comodato d'uso, che vengono disposti in vari punti dell'edificio&nbsp;secondo la&nbsp;<strong>UNI ISO 11665-4:2015</strong>, in modo da evidenziare sia i livelli di Radon a seguito dell'obbligo derivante dalla&nbsp;<strong>L. R. 13/2019</strong>, sia l'effettiva esposizione dell'utente a tale rischio.&nbsp;Il rapporto di prova viene firmato da un&nbsp;<strong>Esperto Qualificato in Radioprotezione di III° grado</strong>.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Il Ns. intervento proposto si compone delle seguenti fasi:</span></p>

                                    <ol>
                                        <li style="text-align:justify"><span style="color:#000000">Sopralluogo ed elaborazione di un&nbsp;<strong>Piano di Campionamento</strong>&nbsp;conforme alla Norma<strong>&nbsp;UNI ISO 11665-4:2015;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Disposizione dei dosimetri del&nbsp;<strong>1° semestre</strong>, da parte del tecnico;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Ritiro</strong>&nbsp;dei dosimetri del <strong>1° semestre</strong>&nbsp;<strong>e </strong><span style="background-color:rgb(255, 255, 255); font-family:sans-serif,arial,verdana,trebuchet ms; font-size:13px">ed invio dei dosimetri presso il laboratorio di radioprotezione con sistema qualità&nbsp;</span><strong>UNI CEI EN ISO/IEC 17025;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Posizionamento</strong>&nbsp;dei dosimetri del <strong>2° semestre</strong>, da parte del tecnico.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Ritiro dei dosimetri del&nbsp;<strong>2° semestre</strong>, da parte del tecnico,&nbsp;ed invio dei dosimetri presso il laboratorio di radioprotezione con sistema qualità&nbsp;<strong>UNI CEI EN ISO/IEC 17025</strong>. I rapporti di prova vengono firmati da&nbsp; <strong>Esperti Qualificati in Radioprotezione di III° grado</strong>.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Elaborazione della&nbsp;<strong>Relazione Annuale </strong>firmato da Esperto Qualificato in Radiporotezione abilitato presso il Ministero del Lavoro e delle Politiche Sociali (obbligatorio secondo L.R. 13/2019);</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Trasmissione agli Enti Competenti (al Comune di appartenenza e all'ARPAC);</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Attuazione eventuali azioni correttive.</span></li>
                                    </ol>

                                    <p style="text-align:justify"><span style="color:#000000">Il prezzo dell'intervento è legato:</span></p>

                                    <ul style="list-style-type:circle">
                                        <li style="text-align:justify"><span style="color:#000000">al numero di dosimetri impiegati</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">alla superficie dei locali a piano terra/seminterrato/interrato.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Per maggiori <strong>INFORMAZIONI e PREVENTIVI </strong>si prega di contattare la Nostra Area Tecnica ai seguenti recapiti: </span><u><a href="mailto:info@dafor.it"><span style="color:#0000CD">info@dafor.it</span></a></u><span style="color:#000000"> - <strong>347 1726816</strong>.</span></p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

