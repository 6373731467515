<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>Bollino Blu per la ristorazione</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                      <h2 style="text-align:justify"><span style="color:#000000"><strong>BOLLINO BLU - PREMESSA</strong>&nbsp;</span></h2>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">L’obbiettivo della Certificazione “Bollino Blu” è quello di offrire al cliente del ristorante, che volontariamente ha scelto la certificazione, tutta una serie di servizi <strong>aggiuntivi</strong> a <strong>garanzia del massimo livello</strong> di attenzione in termini di <strong>igiene</strong>, <strong>sicurezza</strong> ed <strong>informazione alimentare</strong>. Infatti la certificazione ha come obiettivo, oltre alla sicurezza alimentare (e quindi alla riduzione dei rischi), l’attuazione di corretti stili nutrizionali dando informazioni basilari su come evitare i più comuni disturbi dell'alimentazione come: diabete, celiachia, ipertensione, ecc. Per concretizzare questi obiettivi è necessario svolgere un percorso di formazione, che coinvolga tutto il personale dell’esercizio, basato su tecniche di scongelamento di alimenti, igiene del personale e norme di comportamento, pulizia e disinfezione degli utensili, conservazione dei prodotti semilavorati e di quelli finiti. Ma è anche fondamentale svolgere un educazione alimentare e a questo scopo che si è pensato di utilizzare una "<strong><em>guida ai servizi</em></strong>" chiamata <strong>Carta dei Servizi</strong> che il ristorante certificato è in grado di offrire, e che darà informazioni dalle sale separate per fumatori alla tracciabilità dei prodotti, dalcalcolo del valore nutrizionale di un cibo alla sua storia, ai suggerimenti per una corretta alimentazione, perché sempre più è demandato al ristorante il compito difare corretta alimentazione, secondo i dettami della dieta mediterranea.</span></p>

                                    <p style="text-align:justify"><br>
                                    <span style="color:#000000"><strong>AMBITO DI APPLICAZIONE&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                    <br>
                                    Il <strong><em>Bollino Blu</em></strong> è rivolto a tutte le imprese di somministrazione di alimenti e bevande di cui alla <em>Legge 25 agosto 1991 , n. 287</em>, senza alcun pregiudizio per la dimensione aziendale, la ragione sociale, la formula distributiva.<br>
                                    <br>
                                    La <strong>Certificazione</strong> ne costituisce presupposto essenziale e si prefigge il raggiungimento dei seguenti obiettivi:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">riduzione dei rischi da alimentazione a tutela della salute del consumatore;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">condivisione con le autorità di controllo di obiettivi di sicurezza alimentare;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">promozione su produttori e fornitori di azioni volte a conseguire maggiore qualità;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">redazione di report annuale sullo stato di salute degli esercizi pubblici in Italia;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">promozione di campagne annuali monotematiche di educazione nutrizionale da parte di Fipe di concerto con il Ministero della Salute.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>REQUISITI</strong><br>
                                    <br>
                                    Per poter accedere alle procedure di certificazione, l’impresa deve preliminarmente dimostrare di possedere i seguenti "<strong>pre - requisiti</strong>":</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">autorizzazioni sanitarie ed amministrative atte a garantire la conformità dei laboratori, delle attrezzature e dei locali di somministrazione alle vigenti norme locali e nazionali ed assenza di atti prescrittivi in corso da parte della Autorità Sanitaria;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">rispetto sostanziale delle norme di corretta prassi igienica in modo da assicurare un corretto e cosciente rapporto con le sostanze alimentari in tutto il processo di produzione e somministrazione;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">attuazione del piano di autocontrollo igienico-sanitario (H.A.C.C.P.).</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">I requisiti presenti nel regolamento si reggono sostanzialmente su tre capisaldi:<br>
                                    <strong><em>Formazione continua</em></strong>: competenza e consapevolezza quale garanzia basilare per il consumatore;<br>
                                    <strong><em>Carta dei servizi</em></strong>: definizione dello standard qualitativo e dei mezzi di controllo, con particolare attenzione a:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Informazione sulle modalità di preparazione dell’alimento e sull’origine e caratteristiche delle materie prime fondamentali (carne, prodotti ittici, derivati del latte, condimenti, ortofrutta);</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Disponibilità e capacità di proporre preparazioni alimentari alternative adatte per malattie metaboliche, allergie e/o intolleranze alimentari e capacità di adattare le preparazioni della gastronomia locale alle esigenze dettate dallo stile di vita attuale;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Proposta nel menu di piatti che consentano la scelta per la composizione di un pasto a valenza salutistica;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Soddisfazione del cliente, quale guida per il miglioramento continuo.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">La <strong>carta del servizio</strong>, è un documento redatto dal soggetto erogatore e contiene l'esplicitazione degli aspetti contrattuali tra il soggetto erogatore del servizio e i suoi clienti, definendo caratteristiche e livelli qualitativi dei servizi stessi. L'impresa deve descrivere la propria politica per la qualità che intende attuare con detto documento, in termini di obiettivi e impegni per la qualità, deve istituire all'interno della propria organizzazione un sistema che dia evidenza della sua applicazione e dei<br>
                                    risultati ottenuti e deve rendere evidente e disponibile la carta del servizio.<br>
                                    <br>
                                    <strong><em>Rispetto della conformità</em></strong> della impresa e della attività di ristorazione alle specifiche GMP (Good Manufacturing Practise) per le aree critiche per la sicurezza alimentare.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    <br>
                                    <strong>FASE DI ACCOMPAGNAMENTO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                    <br>
                                    Al fine di adeguare l’Esercizio a quanto richiesto dalla Norma Tecnica e in previsione della visita ispettiva di certificazione è fondamentale svolgere un’attività di "<em>accompagnamento alla certificazione</em>".<br>
                                    Questa fase sarà realizzata dai <strong>consulenti</strong> della <strong><em>DAFOR S.r.l.</em></strong> che seguendo le indicazioni della Norma Tecnica in merito ai "pre - requisiti" (Sicurezza alimentare, HACCP, Autorizzazioni Sanitarie) e ai Requisiti del servizio (Formazione e Carta dei Servizi) svolgeranno<br>
                                    attività di:<br>
                                    <br>
                                    &nbsp;Verifica e formazione sulla sicurezza alimentare e sul <strong>H.A.C.C.P.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                    &nbsp;Redazione della <strong>Carta di Servizi </strong>riguardanti Stili Alimentari e Malattie Metaboliche.<br>
                                    <br>
                                    <strong>CERTIFICAZIONE</strong><br>
                                    <br>
                                    Il <strong>Certificato di Conformità</strong> viene emesso dall’Ente di Certificazione ad esito positivo della verifica ed a seguito dell’approvazione del Comitato di Certificazione dell’Ente stesso.<br>
                                    <br>
                                    <strong>RILASCIO MARCHIO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                    <br>
                                    A seguito dell’emissione del Certificato da parte dell’Ente di Certificazione, il Comitato Tecnico Scientifico, anche attraverso alcuni suoi membri allo scopo incaricati, autorizza il rilascio del <strong>Marchio</strong> “<strong><em>Bollino Blu della Ristorazione</em></strong>” correlato al relativo certificato. L’esercizio è in tal<br>
                                    modo autorizzato all’utilizzo del Marchio.&nbsp;&nbsp;&nbsp;<br>
                                    <br>
                                    Al rilascio del marchio, all’esercente sarà fornito il Kit del “Bollino Blu” comprensivo di:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Certificato "Marchio Bollino Blu" con i loghi nel Ministero della Salute e Fipe-Confcommercio;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">La TARGA ufficiale "Marchio Bollino Blu" con i loghi nel Ministero della Salute e Fipe- Confcommercio;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Due Vetrofanie "Marchio Bollino Blu"con i loghi nel Ministero della Salute e Fipe-Confcommercio riportanti le date di validità del Marchio;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Inserimento nella Guida on-line previo invio della scheda (inclusa nel Kit) che riporta le caratteristiche dell’esercizio.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">L’esercente dovrà esporre la Targa all’esterno dell’esercizio e applicare le vetrofanie sull’ingresso dello stesso. Inoltre è autorizzato all’utilizzo del Marchio su altro materiale (es. menù, biglietti da visita, sito dell’esercizio etc.) nel rispetto delle specifiche.</span></p>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

