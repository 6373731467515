<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>Rilievi Micro-Climatici Ambienti di Lavoro</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                    <p style="text-align: justify;"><span style="color:#000000">Il confort microclimatico è fondamentale in tutti gli ambienti di lavoro e nei luoghi aperti al pubblico. Per ottenere situazioni di benessere microclimatico occorre garantire condizioni accettabili sia dal punto di vista delle grandezze termo-igro-anemometriche che caratterizzano il microclima, sia del livello di illuminazione, sia della qualità dell'aria.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">I fattori microclimatici e d’illuminazione negli ambienti di lavoro sono:</span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000">Temperatura;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Aerazione;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Umidità;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Illuminazione.</span></li>
                                    </ul>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <p style="text-align: justify;"><span style="color:#000000">La <strong><em>DAFOR S.r.l.</em></strong> effettua la valutazione del rischio microclima ed i controlli volti a determinare il confort dei lavoratori o di chi si trova a stazionare in un determinato ambiente, secondo quanto previsto dalla vigente (<em>punto 1.9 e 1.10 dell’Allegato IV del D.Lgs. 81/08 e succ. modifiche e integrazioni e norme tecniche a riguardo</em>). I nostri tecnici sono dotati di strumentazione portatile per il monitoraggio di: temperatura, umidità, velocità dell’aria, pressione, intensità luminosa (<em>Luxmetro</em>), CO<sub>2</sub>, ecc…</span></p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

