<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>

            </ul>
            <h2>SERVIZI NEL SETTORE QUALITA'</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/servizi/servizi-qualita.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                      <p style="text-align:justify"><span style="color:#000080"><em><strong>DAFOR S.r.l.</strong></em></span><span style="color:#000000"> crede fortemente nell’impatto che l’etica e la qualità hanno sul mercato e sulle istituzioni.<br>
                                    La Divisione </span><span style="color:#FF0000"><strong>PUNTO QUALITA'</strong></span><span style="color:#000000"> fornisce i propri servizi in materia di Certificazioni di Qualità&nbsp;a tutte le aziende sul territorio della Campania, delle province di Salerno, Napoli, Avellino, Caserta e Benevento e in tutto il Centro-Sud Italia.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Di seguito i servizi proposti:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><strong><u><a href="servizi-qualita-iso9001"><span style="color:#0000CD"><span style="font-size:18px">ISO 9001:2008</span></span></a></u></strong></li>
                                        <li style="text-align:justify"><strong><u><a href="servizi-alimenti-bollino-blu"><span style="color:#0000CD"><span style="font-size:18px">BOLLINO BLU PER LA RISTORAZIONE</span></span></a></u></strong></li>
                                        <li><strong><span style="font-size:18px"><a href="servizi-alimenti-iso22000"><span style="color:#0000CD"><u>ISO 22000</u></span></a></span></strong></li>
                                        <li><strong><a href="servizi-qualita-iso14001"><span style="color:#0000CD"><span style="font-size:18px"><u>ISO 14001 </u></span></span></a></strong></li>
                                        <li><strong><a href="servizi-sicurezza-ohsas"><span style="color:#0000CD"><span style="font-size:18px"><u>OHSAS ISO 18001 </u></span></span></a></strong></li>
                                        <li><strong><u><span style="font-size:18px"><a href="servizi-qualita-ce"><span style="color:#0000FF">MARCATURA E CERTIFICAZIONE CE</span></a></span></u></strong></li>
                                    </ul>



                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

