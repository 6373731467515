<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>Rilievi Vibrazionali negli Ambienti di Lavoro</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                    <p style="text-align: justify;"><span style="color:#000000">La <strong><em>DAFOR. S.r.l. </em></strong>effettua rilievi secondo quanto dettato dal <strong><em>D.Lgs. 81/08 Titolo VIII Capo III e Allegato XXXV </em></strong>in merito all'esposizione dei lavoratori dei rischi derivanti da vibrazioni meccaniche.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">I principali servizi erogati sono:</span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>Predisposizione del Documento di Valutazione dei Rischi da Esposizione a Vibrazioni Meccaniche</strong></span></span></li>
                                    </ul>

                                    <p style="margin-left: 18pt; text-align: justify;"><span style="color:#000000">L'espletamento dell'incarico consiste in:</span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000">sopralluogo in sito;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">rilievo strumentale (mediante analizzatore integratore in classe 1 quattro canali), delle vibrazioni meccaniche trasmesse al sistema mano-braccio ed al corpo intero, generate da macchine,attrezzature ed impianti;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">individuazione dei gruppi omogenei e/o dei lavoratori esposti;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">ricostruzione del ciclo produttivo;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">valutazione del livello di esposizione alle <strong>vibrazioni trasmesse al corpo intero WBV</strong> e calcolo del valore di esposizione giornaliera normalizzato ad 8 ore <strong>A (8)</strong> per tutti i gruppi omogenei individuati;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">valutazione del livello di esposizione alle <strong>vibrazioni trasmesse al sistema mano-braccio HAV</strong> e calcolo del valore di esposizione giornaliera normalizzato ad 8 ore <strong>A (8)</strong> per tutti i gruppi omogenei individuati;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">stesura del documento e relative indicazioni tecniche.</span></li>
                                    </ul>

                                    <p style="margin-left: 36pt; text-align: justify;">&nbsp;</p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>Realizzazione dell'informazione e formazione dei lavoratori</strong></span></span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="color:#000000">Informazione e Formazione dei lavoratori in merito ai rischi derivanti dall'esposizione a vibrazioni meccaniche, l'informazione sarà documentata mediante apposito verbale di riunione, sottoscritto anche dai lavoratori.</span></p>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>Consultazione in merito alla scelta, distribuzione e conservazione dei DPI</strong></span></span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="color:#000000">Predisposizione di apposita procedura, correlata da idonea modulistica, finalizzata ad una corretta gestione dei Dispositivi di Protezione Individuale, scelta, distribuzione, conservazione, manutenzione e sostituzione dei DPI antivibranti.</span></p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

