<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-qualita">Qualità</a></li>

            </ul>
            <h2>Realizzazione Manuale di Qualità ISO:9001</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="col-md-12">

                                    <article>

                                    <div class="testo" id="testo">
                                      <h2 style="text-align:justify"><span style="color:#000000"><strong>ISO 9001:2008</strong>&nbsp; </span></h2>

                                    <p style="text-align:justify"><span style="color:#000000">Le imprese, indipendentemente dalla loro dimensione e dal settore in cui operano, devono far fronte alle crescenti sfide del mercato globale in termini di qualità, tecnologia e sviluppo sostenibile. Per questo diventa importante, identificare chiaramente i propri obiettivi: per migliorare le proprie performance e assicurare che le esigenze del cliente siano chiaramente comprese, concordate e soddisfatte.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La nuova norma ISO 9001:08 (VISION 2008 entrata in vigore dal 15 Novembre 2009) rappresenta oggi il nuovo riferimento, riconosciuto a livello mondiale, per la certificazione del <strong>sistema di gestione per la qualità</strong> delle organizzazioni di tutti i settori produttivi e di tutte le dimensioni.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    La revisione 2008 delle norme ISO 9000 (la quarta dal 1987) ha come principale obiettivo <strong>l'applicabilità ad ogni tipologia aziendale</strong> e un'impostazione redazionale totalmente compatibile per una <strong>gestione integrata</strong> con altre norme certificabili. &nbsp;&nbsp;&nbsp;<br>
                                    &nbsp;<strong>I vantaggi per l’impresa </strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Il Sistema di Gestione per la Qualità (SGQ) permette di:</strong></span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Gestire le attività in un’ottica di processo;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Incentivare il management nel riesame dei punti critici dell'organizzazione;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Creare procedure e condividere strategie per aumentare il coinvolgimento del personale;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Migliorare la comunicazione con i fornitori;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Monitorare la soddisfazione del cliente;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Ridurre i costi legati a una gestione non efficiente e migliorare continuamente il proprio prodotto/servizio;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Miglioramento dell'<strong>immagine aziendale</strong>.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Vantare una <strong>certificazione</strong> di <strong>validità mondiale</strong>, elemento aggiuntivo per&nbsp; la&nbsp; partecipazione a gare d'appalto</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Punti chiave</strong> &nbsp;<br>
                                    La ISO 9000:2000 pone al <strong>centro</strong> della realizzazione di un sistema di gestione:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">il <strong>cliente</strong> e la sua piena soddisfazione;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">la visione dell'azienda come un <strong>insieme di processi</strong> tra loro in stretta relazione e finalizzati a fornire prodotti che rispondano in modo costante ai requisiti fissati;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">l'importanza di perseguire il <strong>continuo miglioramento</strong> delle prestazioni.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Gestire la qualità significa gestire l'efficacia dei propri processi attraverso:</strong></span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">la conoscenza, la gestione e il monitoraggio dei <strong>processi</strong>;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">la capacità di coinvolgere le <strong>risorse umane</strong>;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">la centralità del ruolo dell'<strong>Alta Direzione </strong>aziendale.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">La nuova norma chiede di individuare e misurare i <strong>punti dei processi</strong> che generano valore verso il mercato, considerando l'azienda come un insieme di clienti-fornitori tra loro concatenati.<br>
                                    In questo contesto ciascun processo riceve input da <strong>fornitori interni/esterni</strong> e fornisce prodotti o servizi a <strong>clienti interni/esterni</strong> .</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Iter di Certificazione&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong><br>
                                    <strong>Punto Qualità </strong>(<em>DAFOR &nbsp;S.r.l.</em>) è in grado di fornire alle aziende di ogni dimensione <strong>specifiche competenze</strong> in tutti i settori di attività legati al mondo dell'agroalimentare, del turismo, della formazione, dei servizi e della Pubblica Amministrazione e gestione del territorio in senso lato.<br>
                                    <br>
                                    L'iter di certificazione prevede:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">La formulazione di <strong>un'offerta economica</strong> (senza impegno);</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">La definizione delle Politica per la Qualità, degli Obiettivi e dei requisiti del prodotto/servizio dell’Azienda da parte dei consulenti di <strong>Punto Qualità </strong>(<em>DAFOR &nbsp;S.r.l.</em>);</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">La predisposizione della <strong>documentazione di pianificazione e di registrazione del Sistema di Qualità </strong>(Manuale di Gestione per la Qualità, Procedure Organizzative, Istruzioni Operative e Modulistica di supporto) sempre dai nostri consulenti;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">La <strong>formazione del Personale</strong> sugli aspetti della Qualità effettuata dai nostri consulenti;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">L’<strong>assistenza</strong>&nbsp; in fase di certificazione del Sistema di Gestione di Qualità in occasione della verifica dell’Organismo di Certificazione per il “mantenimento” della certificazione.</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">La <strong>fase di Certificazione</strong> da parte <strong><em>dell’Ente di Accreditato Accredia </em></strong><em>(ex Sincert)</em> che prevede la visita di valutazione in Azienda, il&nbsp; <strong>rilascio del certificato</strong> e il <strong>mantenimento per il 2° e 3° anno</strong>.</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

