<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-sicurezza">Sicurezza</a></li>

            </ul>
            <h2>Igiene e Sicurezza delle Piscine</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                      <p style="text-align:justify"><span style="color:#000000"><span style="font-size:16px"><strong>L'AUTOCONTROLLO IN PISCINA ED IL PIANO DI VALUTAZIONE DEL RISCHIO </strong></span></span></p>

                                    <p style="text-align:justify"><br>
                                    <span style="color:#000000">La recente approvazione dell’</span><a><span style="color:#000000"><strong>Accordo Stato - Regioni Province autonome del 16 Gennaio 2003</strong></span></a><span style="color:#000000"> e dei relativi atti di recepimento da parte delle regioni, prevede che a partire dal 1° gennaio 2005, tutti gli impianti natatori, a prescindere dalle loro dimensioni, adottino un <strong>piano di autocontrollo redigendo un documento di valutazione dei rischi</strong>, atto a prevenire i rischi derivanti alla salute degli utenti, dovuti ad una cattiva gestione degli impianti da parte dei gestori. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    Infatti il documento deve tenere conto dei seguenti princìpi:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    a) analisi dei potenziali pericoli igienico-sanitari per la piscina; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    b) individuazione dei punti o delle fasi in cui possono verificarsi tali pericoli e definizione delle&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; relative misure preventive da adottare;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    c) individuazione dei punti critici e definizione dei limiti critici degli stessi; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    d) definizione del sistema di monitoraggio;<br>
                                    e) individuazione delle azioni correttive; &nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    f) verifiche del piano e riesame periodico, anche in relazione al variare delle condizioni iniziali, delle analisi dei rischi, dei punti critici, e delle procedure in materia di controllo e sorveglianza.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">La <strong><em>DAFOR S.r.l.</em></strong> con il proprio marchio PUNTO QUALITA' fornisce la consulenza e l’assistenza necessari a:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">Implementazione piani di autocontrollo e loro corretta applicazione e gestione incluso il servizio analisi di laboratorio;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Realizzazione documento di valutazione dei rischi per le piscine;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">Riesame e revisione periodica del documento di valutazione dei rischi.</span></li>
                                    </ul>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>ANALISI DI LABORATORIO</strong></span></p>

                                    <p style="text-align:justify"><br>
                                    <span style="color:#000000">E' necessario che in fase di progettazione del piano di autocontrollo o di verifica dello stesso sia sviluppato ed applicato un piano di campionamento conforme alla normativa di riferimento e alle indicazioni degli organi pubblici di controllo e idoneo a fornire precise indicazioni sull'igiene di superfici e attrezzature e sulla qualità chimico/microbiologica/fisica dell’acqua di piscina, secondo quanto dettato dalla <em>Tabella A&nbsp; Allegato 1</em> del suddetto Accordo.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
                                    Fondamentali sono la pianificazione delle analisi (Piano di analisi) ed il prelievo dai quali dipendono l'efficacia e la significatività dell'esito analitico.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">&nbsp;Per questo i consulenti della <em><strong>DAFOR S.r.l.</strong></em> gestiscono direttamente questo servizio.Le Analisi vengono effettuate presso laboratori (<strong><em>nostri servers</em></strong>) certificati dai ministeri competenti.<br>
                                    Tutti i referti delle analisi sono inviati al cliente accompagnati da relazione di spiegazione ed interpretazione dei risultati.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Inoltre grazie alla dotazione di <strong>Strumentazione Portatile</strong> (<em>Fotometri Multiparametro</em>) si eseguono direttamente dal cliente la misura dei seguenti parametri: <em>alcalinità, cloro libero e totale, acido cianurico, durezza, pH, bromo, rame lebero e totale, ferro e ozono</em>.</span></p>


                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

