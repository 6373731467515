<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Prodotti</li>
            </ul>
            <h2>Strumenti di Misura</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">

                                <div class="">

                                    <article>
                                    <div class="testo" id="testo">
                                      <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:18px"><strong>STRUMENTI DI MISURA</strong></span>&nbsp;</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">La <strong><em>DAFOR &nbsp;S.r.l. </em></strong>assiste gli operatori del settore nella scelta di prodotti e tecnologie per assicurare la massima efficacia alle migliori condizioni economiche nel rispetto delle normative proposte alla sicurezza degli utenti.</span></p>

                                    <p style="text-align: justify;"><span style="color:#000000">La <strong><em>DAFOR &nbsp;S.r.l. </em></strong>può fornire l'intera gamma degli strumenti necessari alla gestione dell'attività di monitoraggio di parametri ambientali come:</span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000">Misuratori di temperatura;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Data logger;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Misuratori di Umidità;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Misuratori di Velocità;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Strumenti multifunzione;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Analizzatori di gas;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Idrometri;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Strumenti per l'analisi dell'acqua;</span></li>
                                        <li style="text-align: justify;"><span style="color:#000000">Misuratori di luce/suono.</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

