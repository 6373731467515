<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-ambiente">Ambiente</a></li>

            </ul>
            <h2>Sanificazione Ambientale</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">

                                    <p style="text-align:center"><span style="font-size:18px"><span style="color:rgb(255, 0, 0)"><strong>La&nbsp;Sanificazione è una cosa seria. Fidati dei professionisti, in ballo c'è la tua salute!</strong></span></span></p>

                                    <h2 style="text-align:center"><strong><span style="color:rgb(0, 0, 255)">Elimina virus e batteri dalle superfici</span></strong></h2>

                                    <p style="text-align:justify"><span style="color:rgb(0, 0, 0)">La cura e l’igiene degli ambienti sono ora più che mai fondamentali per la salute pubblica. La sanificazione in particolare si dimostra essere un ulteriore elemento di sicurezza utile a prevenire rischi di contaminazione e contagio.</span></p>

                                    <p style="text-align:justify"><span style="color:rgb(0, 0, 0)">La</span><span style="color:rgb(0, 0, 255)"><strong> DAFOR S.r.l.</strong></span><span style="color:rgb(0, 0, 0)">, con la propria divisione </span><span style="color:rgb(255, 0, 0)"><strong>PUNTO QUALITÀ</strong></span><span style="color:rgb(0, 0, 0)">, è una società </span><a><span style="color:rgb(0, 0, 255)"><u><strong>ABILITATA</strong></u></span></a><span style="color:rgb(0, 0, 0)"> all'attività di disinfezione, disinfestazione, derattizzazione e sanificazione presso la CCIAA di Salerno e iscritta nell’apposito registro, secondo quanto dettato dalla Legge n° 82 del 27/01/1994 e dal D.M. n. 274 del 7 luglio 1997.</span></p>

                                    <p style="text-align:justify"><span style="color:rgb(0, 0, 0)">Tra le varie attività è specializzata nel trattamento specifico di <strong>sanificazione ambientale&nbsp;con disinfettante&nbsp;a base Cloro e di Sali Quaternari d’ammonio </strong>per&nbsp;rendere uffici, abitazioni, locali commerciali, mense, magazzini alimentari e aziende LUOGHI PIÙ SICURI.</span></p>

                                    <p style="text-align:justify"><span style="color:rgb(0, 0, 0)">Per la Sanificazione si utilizza anche la <strong>METODOLOGIA AD OZONO</strong> riconosciuta come presidio per la sterilizzazione degli ambienti (Decr. Ministero della Sanità, Dipartimento Alimentare e Nutrizione Veterinaria, Protocollo n. 24482 del 31/07/1996), ai sensi del D.Lgs. 81/08.</span></p>

                                    <h5 style="text-align:justify"><span style="color:rgb(0, 0, 0)"><strong><span style="font-size:16px"><u>Sanificazione ambientale, disinfezione completa e certificata e nessun effetto residuale.</u></span></strong></span></h5>

                                    <p style="text-align:justify"><span style="color:rgb(0, 0, 0)">Si distinguono 4 tipi di aree d’intervento:</span></p>

                                    <ol>
                                        <li style="text-align:justify"><span style="color:rgb(0, 0, 0)"><strong>Sanificazione con nebulizzatori mediante disinfettanti specifici autorizzati dal Ministero della &nbsp;Salute, per AMBIENTI INTERNI:&nbsp;</strong>uffici, androni di edifici civili e abitazioni, locali mensa, locali commerciali;</span></li>
                                        <li style="text-align:justify"><span style="color:rgb(0, 0, 0)"><strong>Sanificazione METODOLOGIA AD OZONO, per AMBIENTI INTERNI:&nbsp;</strong>uffici, androni di edifici civili e abitazioni, locali mensa, locali commerciali;</span></li>
                                        <li style="text-align:justify"><span style="color:rgb(0, 0, 0)"><strong>Sanificazione con atomizzatore&nbsp;e/o nebulizzatori con disinfettanti specifici autorizzati dal Ministero della &nbsp;Salute, per AMBIENTI INTERNI DI GRANDI DIMENSIONI:&nbsp;</strong>alberghi, capannoni aziendali, magazzini, supermercati;</span></li>
                                        <li><span style="color:rgb(0, 0, 0)"><strong>Sanificazione con atomizzatore&nbsp;con disinfettanti specifici autorizzati dal Ministero della &nbsp;Salute, per AREE ESTERNE DI GRANDI DIMENSIONI:&nbsp;</strong>parcheggi, Stabilimenti Balneari, Lidi, Villaggi, Campeggi ed aree esterne di capannoni.</span></li>
                                    </ol>

                                    <p><span style="color:rgb(0, 0, 0)"><strong><u>Descrizione del servizio</u></strong></span></p>

                                    <p style="text-align:justify"><span style="color:rgb(0, 0, 0)">Nello specifico, <strong>la sanificazione professionale, prevede la distribuzione per&nbsp;nebulizzazione di sanificanti</strong> <strong>con&nbsp;marcato&nbsp;effetto </strong>b<strong>attericida e virucida </strong>nei confronti di un ampio spettro di batteri gram-positivi, gram-negativi e virus. Ogni microscopica gocciolina nebulizzta ha una carica positiva e, come un piccolo magnete, viene attratta dalla superficie garantendo la disinfezione raggiungendo anche i bordi e il retro degli oggetti. I&nbsp;disinfettanti utilizzati, sono prodotti specifici certificati e registrati CE e Ministero della Salute. A fine servizio viene rilasciato il </span><a><u><span style="color:rgb(0, 0, 205)"><strong>Certificato di Sanificazione</strong></span></u></a><span style="color:rgb(0, 0, 0)"> con le relative schede tecniche dei prodotti sanificanti usati.</span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p><span style="color:rgb(0, 0, 0)"><strong><u>Cosa fare prima del trattamento?</u></strong></span></p>

                                    <p style="text-align:justify"><span style="color:rgb(0, 0, 0)">Sono necessari alcuni accorgimenti affinché il servizio venga eseguito in modo sicuro e con la massima efficacia. Si consiglia di verificare che tutte le <strong>zone interne dei locali da bonificare siano sgombre e in buono stato di pulizia</strong>. Inoltre è necessario:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:rgb(0, 0, 0)">coprire tutte le apparecchiature elettroniche con plastica (tastiere, monitor, stampanti);</span></li>
                                        <li style="text-align:justify"><span style="color:rgb(0, 0, 0)">rimuovere qualsiasi materiale che potrebbe essere danneggiato dall'umidità (carte, opere d'arte o altri materiali);</span></li>
                                        <li style="text-align:justify"><span style="color:rgb(0, 0, 0)">rimuovere dal pavimento eventuali residui di origine alimentare, segatura ed ogni altro materiale possa costituire un ostacolo alla diffusione della soluzione;</span></li>
                                        <li style="text-align:justify"><span style="color:rgb(0, 0, 0)">rimuovere tutti gli alimenti dall'area da trattare o metterli al riparo (nel frigorifero o nella dispensa) utilizzando contenitori ben sigillati.</span></li>
                                    </ul>

                                    <p><span style="color:rgb(0, 0, 0)"><strong><u>Cosa fare dopo il trattamento?</u></strong></span></p>

                                    <p><span style="color:rgb(0, 0, 0)">Dopo aver lasciato asciugare il prodotto sanificante è importante <strong>areare le aree trattate</strong>.</span></p>

                                    <p><span style="color:rgb(0, 0, 0)"><strong><u>Da chi viene svolto il servizio?</u></strong></span></p>

                                    <p style="text-align:justify"><span style="color:rgb(0, 0, 0)">L’intervento viene effettuato da <strong>TECNICI SPECIALIZZATI </strong>e al termine del trattamento verrà rilasciata <strong>ATTESTATO&nbsp;di&nbsp;avvenuto “Servizio di Sanificazione”</strong> con tutte le schede tecniche e di sicurezza di prodotti e macchinari utilizzati. Inoltre, su richiesta, è possibile effettuare anche il&nbsp;<strong>TAMPONE DI VERIFICA della carica batterica</strong>.</span></p>

</div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

