<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>

            </ul>
            <h2>Edilizia</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/servizi/servizi-edilizia.jpg" style="max-height:496px; width:100%; object-fit: cover;  object-position: bottom bottom;" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="col-md-12">

                                    <article>

                                    <div class="testo" id="testo">

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Punto Qualita' </strong>(<strong><em>DAFOR &nbsp;S.r.l.</em></strong>) è in grado di fornire <strong>la consulenza e il supporto nel settore edilizia</strong>. Servizi rivolti ad <strong>imprese e privati.</strong></span></p>

                                    <p style="text-align:justify"><span style="color:#000000">I principali servizi di consulenza erogati sono:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Presentazione della CILA (Comunicazione di Inizio Lavori Asseverata);</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Presentazione della SCIA (Segnalazione Certificata di Inizio Attività);</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Presentazione della DIA (Denuncia di Inizio Attività);</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Presentazione documenti per il Catasto (Planimetrie e visure)</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Presentazione del Certificato Energetico;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Progettazioni impianti ed edili (Rilievi e planimetrie);</strong></span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">L'espletamento dell'incarico consiste in:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Sopralluogo in sito</strong>;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Elaborazione delle documentazioni; </strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Presentazione delle pratiche agli organi competenti e terrritoriali;</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>Formazone e Assistenza; </strong></span></li>
                                    </ul>



                                    </div>
                                    </article>







                                      </div>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

