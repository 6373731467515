<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-qualita">Qualità</a></li>

            </ul>
            <h2>Marcatura &amp; Certificazione CE</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">
                                <div class="">

                                    <article>

                                    <div class="testo" id="testo">
                                      <p><span style="color:#000000"><span style="font-size:20px"><strong>La Marcatura CE e la Certificazione CE</strong></span></span></p>

                                    <h3 style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Un supporto completo al cliente nell’esecuzione di tutte le attività previste dalle direttive comunitarie per la certificazione CE e la marcatura CE di prodotti.</span></span></h3>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>PUNTO QUALITA’ divisione di <em>DAFOR S.r.l.</em></strong> effettua la pianificazione, la progettazione, e l’implementazione di tutte le fasi necessarie alla marcatura CE del vostro prodotto.&nbsp;La consulenza ingegneristica offerta da <strong><em>DAFOR S.r.l.</em></strong>, fornisce i seguenti servizi previsti dalla Direttive Comunitarie:</span></span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Analisi dei prodotti/dispositivi/macchine;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Redazione del fascicolo Tecnico;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Analisi dei rischi;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Redazione del manuale d’uso;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Interfacciamento con Enti Notificatori e Laboratorio Prove (se necessario);</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Assistenza normativa;</span></span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>Cos’è la marcatura CE?</strong></span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">La marcatura CE e la dichiarazione di conformità sono il passaporto che permette ai prodotti la libera circolazione in Europa.</span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">La&nbsp;<em>marcatura CE</em>&nbsp;è un simbolo che sta ad indicare la&nbsp;<em>"Conformity Essential"</em>&nbsp;e che deve essere apposto su determinate tipologie di prodotti per attestarne la conformità o rispondenza ai requisiti essenziali previsti da Direttive in materia di sicurezza, sanità pubblica, tutela del consumatore, ecc.</span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">L'apposizione del marchio è prescritta per legge per poter commercializzare il prodotto nei paesi aderenti allo Spazio Economico Europeo (SEE), avendo il compito di garantire ai consumatori che il prodotto ha le necessarie caratteristiche di sicurezza d'uso.</span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>Chi la esegue?</strong></span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>La valutazione di conformità</strong>&nbsp;può essere fatta, nella maggior parte dei casi, dal fabbricante che in totale autonomia può procedere alla valutazione di conformità conservando la relativa documentazione, ossia il&nbsp;<strong>fascicolo tecnico </strong>in cui sono raccolte le indicazioni dei criteri adottati per rispondere ai requisiti di sicurezza e ridurre al minimo il rischio residuo.</span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">In altri casi, nello specifico per i prodotti ritenuti dal legislatore europeo potenzialmente più rischiosi, la valutazione di conformità necessita dell'intervento di un Organismo Notificato che, alternativamente o congiuntamente:</span></span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">verifica e approva, mediante esami e prove su un prototipo, la progettazione del prodotto;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">approva unitariamente ogni esemplare del prodotto (solo la produzione/installazione ovvero anche la progettazione);</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">approva e sorveglia il sistema di garanzia del fabbricante (assumendo come riferimento le norme UNI EN ISO 9000): garanzia qualità totale della produzione o dei prodotti;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">La Marcatura CE unita alla Dichiarazione di Conformità rende il prodotto conforme a tutte le esigenze di sicurezza, salute, igiene e protezione dell'ambiente ad esso applicabili.</span></span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">&nbsp;<strong>Come si esegue?</strong></span></span></p>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Definizione delle caratteristiche di prodotto, delle modalità di produzione e dei controlli;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Pianificazione delle attività per l’ottenimento della Marcatura CE;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Valutazione delle conformità secondo gli standard definiti</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Verifiche dei risultati derivanti dai rapporti di prova dei laborat;ori presso i quali sono stati eseguiti i controlli e pianificazione di eventuali azioni correttive;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Supporto alla realizzazione del Fascicolo Tecnico e verifica dei contenuti dello stesso;</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Assistenza all’ottenimento della Marcatura CE.</span></span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>A chi si rivolge?</strong></span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">La marcatura CE è prevista, astrattamente, per tutti i prodotti commercializzati entro lo Spazio Economico Europeo (SEE) che devono inderogabilmente essere conformi almeno alla Direttiva 2001/95/CE. Di converso, la marcatura CE con l'apposizione del marchio CE è prevista per:</span></span></p>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Dispositivi medici impiantabili attivi</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Apparecchi a gas</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Teleferiche per il trasporto di persone</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Eco-design di prodotti relativi ai consumi energetici</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Attrezzature per la misurazione della compatibilità elettromagnetica</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Dispositivi di protezione da atmosfere esplosive</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Esplosivi per uso civile</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Bollitori per acqua calda alimentati da combustibile liquido o gassoso</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Frigoriferi e freezer per uso domestico</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Dispositivi di diagnosi medica in vitro</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Ascensori</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Strumenti di pesatura</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Dispositivi medici</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Attrezzature con emissione di rumore nell’ambiente</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Strumenti di pesatura non automatici</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Dispositivi di protezione personale</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Strumenti di pressione</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Dispositivi pirotecnici</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Dispositivi radio e per telecomunicazioni</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Prodotti per hobby e tempo libero</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Giocattoli</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Recipienti a pressione</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Materiali e prodotti da costruzione</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Macchine</span></span></li>
                                    </ul>

                                    <p style="text-align: justify;">&nbsp;</p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px"><strong>Quali sono i vantaggi?</strong></span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">La marcatura CE di un prodotto implica la presunzione che lo stesso sia conforme alle Direttive Europee.</span></span></p>

                                    <p style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Il valore giuridico della marcatura CE comporta:</span></span></p>

                                    <ul>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">Non subire limitazioni alla libera circolazione del prodotto nell'area del Mercato Unico Europeo ai sensi degli articoli 28 e 30 del Trattato CE.</span></span></li>
                                        <li style="text-align: justify;"><span style="color:#000000"><span style="font-size:14px">L'inversione dell'onere della prova sul rispetto dei requisiti essenziali stabiliti dalle Direttive nuovo approccio.</span></span></li>
                                    </ul>

                                    <p style="text-align: justify;"><span style="font-size:14px"><span style="color:#000000">In particolare, quest'ultimo punto non è cosa da poco. Posto che i prodotti sono immessi nel mercato sotto la responsabilità del fabbricante, del mandatario o dell'importatore, avvalersi di una&nbsp;</span><a title="Finzione giuridica (la pagina non esiste)"><span style="color:#000000">finzione giuridica</span></a><span style="color:#000000">&nbsp;che inverte l'onere della prova significa che un prodotto sottoposto a marcatura CE può essere contestato solo se la violazione dei requisiti della Direttiva di riferimento venga concretamente provata in giudizio.</span></span></p>

                                    <p style="text-align: justify;"><span style="font-size:14px"><span style="color:#000000">Di converso la violazione degli obblighi riconducibili alla marcatura CE ovvero l'indebita marcatura CE, nel caso questa sia obbligatoria, comporta il ritiro del prodotto da parte dell'autorità competente di vigilanza sul mercato. Le procedure e le misure di sanzione previste per la contraffazione della marcatura CE variano secondo la legislazione vigente nei singoli Paesi membri. Proporzionalmente all'entità dell'infrazione, gli operatori economici sono soggetti a pene che vanno dalla sanzione pecuniaria alla detenzione. Qualora l'infrazione non comporti rischi immediati per la salute degli utilizzatori, il produttore non sarà tenuto al ritiro del bene dal mercato, a condizione che intraprenda le misure necessarie per ottenere la conformità CE. Restano comunque ferme le disposizioni riguardanti il&nbsp;</span><a title="Diritto civile"><span style="color:#000000">Diritto civile</span></a><span style="color:#000000">.</span></span></p>

                                    <p style="text-align: justify;"><span style="font-size:14px"><span style="color:#000000">In campo civilistico, posto che la marcatura CE in regime di obbligatorietà rappresenta una pre-condizione all'immissione sul mercato dei prodotti, il difetto di marcatura CE completa la fattispecie della vendita di&nbsp;</span><a title="Aliud pro alio"><span style="color:#000000">Aliud pro alio</span></a><span style="color:#000000">. Con la formula&nbsp;aliud pro alio&nbsp;s'intende l'inadempimento contrattuale nelle obbligazioni a prestazioni corrispettive&nbsp;per cui il compratore che dovesse riscontrare tale difformità sarà tutelato dalla disciplina generale sulla&nbsp;</span><a title="Risoluzione per inadempimento"><span style="color:#000000">Risoluzione per inadempimento</span></a><span style="color:#000000">&nbsp;ex&nbsp;Art. 1453 e successivi del Codice Civile.</span></span></p>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

