<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Servizi</li>
                <li><a href="servizi-alimenti">Alimenti</a></li>

            </ul>
            <h2>ISO 22000</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc">
                            <div class="courses-overview">

                                <div class="">

                                   <article>
                                    <div class="testo" id="testo">

                                    <p style="text-align:justify"><span style="color:#000000">La <strong>ISO 22000 "Food safety management systems- Requirements"</strong> è uno <strong>standard volontario</strong> per la certificazione di <strong>Sistemi di Gestione della Sicurezza</strong> in campo alimentare.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000">Gli obiettivi principali&nbsp;di questa nuova norma sono:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>armonizzare i differenti standard specifici riguardanti la sicurezza alimentare</strong></span></li>
                                        <li style="text-align:justify"><span style="color:#000000">fornire&nbsp;uno strumento per <strong>sviluppare il metodo HACCP in tutto il sistema produttivo del settore alimentare</strong>.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">Lo standard si rivolge a tutti gli attori coinvolti nella catena alimentare: aziende agricole, mangimifici, allevamenti, aziende agro-alimentari, supermercati, rivenditori al dettaglio e all’ ingrosso, aziende di trasporto, produttori di packaging e macchinari alimentari, aziende fornitrici di prodotti per la pulizia e sanificazione, fornitori di servizi.</span></p>

                                    <p style="text-align:justify"><span style="color:#000000"><strong>Punti chiave</strong></span></p>

                                    <p style="text-align:justify">&nbsp;</p>

                                    <p style="text-align:justify"><span style="color:#000000">I <strong>punti chiave</strong> della norma sono:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000">la <strong>comunicazione interattiva</strong>, tra l'azienda interessata e i diversi attori a monte e a valle della catena di fornitura;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">il <strong>sistema di gestione aziendale</strong>;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">il <strong>controllo di processo</strong>;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">la <strong>metodologia HACCP</strong>, applicata secondo quanto previsto dal documento FAO/OMS <em>Codex Alimentarius</em> , con particolare attenzione rivolta all'analisi dei pericoli che diventa lo strumento guida per la politica di sicurezza alimentare aziendale;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000">la <strong>gestione dei pericoli per la sicurezza igienica tramite misure di controllo operative</strong>.</span></li>
                                    </ul>

                                    <p style="text-align:justify"><span style="color:#000000">I <strong>punti di forza</strong> della norma sono:</span></p>

                                    <ul>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>obiettivo chiaro e concreto</strong> del modello gestionale: <strong>sicurezza alimentare</strong> e non qualità in senso lato;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>compatibilità e integrabilità completa con la norma UNI EN ISO 9001: 2000</strong> ed altri modelli gestionali simili (UNI EN ISO 14001: 1996 etc.);</span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>integrazione del metodo HACCP</strong> e dei principi del <em>Codex Alimentarius </em>all'interno del modello gestionale;</span></li>
                                        <li style="text-align:justify"><span style="color:#000000"><strong>soddisfazione di tutte le parti interessate</strong>: autorità preposte al controllo dei requisiti di legge, consumatore, intermediari commerciali, altre aziende alimentari</span></li>
                                    </ul>


                                    </div>
                                    </article>







                                      </div>

                            </div>
                </div>
            </div>
        </div>
    </div>
</div>

